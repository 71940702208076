import React from 'react';

const TextInput = React.forwardRef((props, ref) => {
    const errorStyle = 'border-red-300 focus:ring-red-500 focus:border-red-500';
    const normalStyle = 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500';

    return (
        <div>
            <div className="flex justify-between">
                <label htmlFor={props.name} className="block text-sm font-medium text-gray-700">
                    {props.label}
                </label>
                {props.optional && <p className="text-xs text-gray-500">Optional</p>}
            </div>
            <div className="mt-1">
                <input
                    data-cy={props.data_cy}
                    id={props.name}
                    type={props.type}
                    name={props.name}
                    className={`shadow-sm block w-full sm:text-sm border ${props.errors ? errorStyle : normalStyle}  rounded-md py-2 px-4`}
                    placeholder={props.placeholder}
                    ref={ref}
                    defaultValue={props.value}
                    {...props}
                />
            </div>
            {props.errors && (
                <p className="mt-2 text-sm text-red-600" id="email-error">
                    This field is required
                </p>
            )}
        </div>
    );
});

export default TextInput;
