import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import qs from 'query-string';

import searchData from '../helpers/searchFile.json'

const {
    workLevel: workLevelData,
} = searchData

const SearchContextV2 = createContext();

const SearchProviderV2 = ({ children }) => {
    // Data from DB
    const [branches, setBranches] = useState([])
    const [allJobs, setAllJobs] = useState([])
    const [branchJobs, setBranchJobs] = useState([])
    const [cities, setCities] = useState([])
    const [employmentTypes] = useState(searchData.employmentType)
    const [workLevels] = useState(searchData.workLevel)
    const [dataLoaded, setDataLoaded] = useState(false)

    // Filters
    const [branch, setBranch] = useState()
    const [job, _setJob] = useState([])
    const [employmentType, setEmploymentType] = useState()
    const [workLevel, setWorkLevel] = useState([])
    const [city, _setCity] = useState()
    const [remote, setRemote] = useState(false)
    const [openToMove, setOpenToMove] = useState(false)
    const [smartSearch, setSmartSearch] = useState(true)

    const [queryString, setQueryString] = useState('')
    const [queryObject, setQueryObject] = useState(null)
    const [filtersInitialized, setFiltersInitialized] = useState(false)

    // Fetch branches, jobs & cities
    useEffect(() => {
        if (!branches.length) {
            Promise.all([
                axios.get(`${process.env.STUDIO_WEB_API}/jobtitles/both`),
                axios.get(`${process.env.STUDIO_WEB_API}/joblocations`)
            ]).then(([res1, res2]) => {
                const { data: { data: { branches, jobtitles } } } = res1;
                const { data: { data: joblocations } } = res2;

                setBranches(branches)
                setAllJobs(jobtitles)
                setBranchJobs(jobtitles)
                setCities(joblocations)
                setDataLoaded(true)
            })
        }
    }, [])

    useEffect(() => {
        setQueryStringFromFilters()
     },[
        branch,
        job,
        employmentType,
        workLevel,
        city,
        remote,
        openToMove,
        smartSearch
    ])

    useEffect(() => {
        if (filtersInitialized) {
            setJob(job.filter(j => branch && j.branch === branch._id))
            setBranchJobs(getBranchJobs())
        }
    }, [branch])

    useEffect(() => {
        if (job.length === 1 && !branch) {
            setBranch(branches.find(b => b._id === job[0].branch))
        }
    }, [job])

    const setCity = (cityName) => _setCity(cities.find(c => c.name === cityName))

    const setJob = (_job) => {
        const j = Array.isArray(_job) ? _job : (_job ? [_job] : [])
        _setJob(j)
    }

    const getBranchJobs = (b) =>
        b || branch ? allJobs.filter(j => j.branch === (b || branch)._id) : allJobs

    const setFiltersFromQueryString = (queryString) => {
        const query = qs.parse(queryString) || {}

        let branch
        if (query.branch) {
            branch = branches.find(b => b.enum === query.branch)
            if (branch) setBranch(branch)
            else delete query.branch
        }

        if (query.job) {
            const queryJobs = query.job.split(',')
            const job = getBranchJobs(branch).filter(j => queryJobs.includes(j.enum))
            if (job.length) setJob(job)
            else delete query.job
        }

        if (query.employmentType) {
            const employmentType = employmentTypes.find(e => e.name === query.employmentType)
            if (employmentType) setEmploymentType(employmentType)
            else delete query.employmentType
        }

        if (query.workLevel) {
            const queryLevels = query.workLevel.split(',')
            const workLevel = workLevels.filter(w => queryLevels.includes(w.name))
            if (workLevel.length) setWorkLevel(workLevel)
            else delete query.workLevel
        }

        if (query.city) {
            setCity(query.city)
        }

        if (query.remote === 'True') setRemote(true)
        if (query.openToMove === 'True') setOpenToMove(true)

        setSmartSearch(query.type === 'embed')
        setBranchJobs(getBranchJobs(branch))
        setTimeout(() => setFiltersInitialized(true), 100)
    }

    const setQueryStringFromFilters = () => {
        const query = {}
        if (branch) query.branch = branch.enum
        if (job && job.length) query.job = job.map(j => j.enum).join(',')
        if (employmentType) query.employmentType = employmentType.name
        if (workLevel.length) query.workLevel = workLevel.map(w => w.name).join(',')
        if (city) query.city = city.name
        if (remote) query.remote = 'True'
        if (openToMove) query.openToMove = 'True'
        query.type = smartSearch ? 'embed' : 'logical'

        setQueryString(qs.stringify(query))
        setQueryObject(query)
    }

    return (
        <SearchContextV2.Provider
            value={{
                // data
                branches,
                jobs: branchJobs,
                cities,
                employmentTypes,
                workLevels,
                // filters
                branch,
                setBranch,
                job,
                setJob,
                employmentType,
                setEmploymentType,
                workLevel,
                setWorkLevel,
                city,
                setCity,
                remote,
                setRemote,
                openToMove,
                setOpenToMove,
                smartSearch,
                setSmartSearch,
                // helpers
                setFiltersFromQueryString,
                setFiltersInitialized,
                queryString,
                queryObject,
                dataLoaded
            }}
        >
            {children}
        </SearchContextV2.Provider>
    );
};

export { SearchProviderV2, SearchContextV2 };
