import React from 'react';

const Badge = (props) => {
    const { text, bgColor, textColor } = props;
    return (
        <div className={`py-1 px-3 ${bgColor} ${textColor} rounded-xl`}>
            <p>{text}</p>
        </div>
    );
};

export default Badge;
