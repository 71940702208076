import React from 'react';

const ButtonIcon = (props) => {
    return (
        <button
            onClick={props.onClick}
            type="button"
            className={`inline-flex items-center gap-2 px-4 py-2 border shadow-sm font-medium rounded-md ${props.theme} `}
        >
            {props.children}
        </button>
    );
};

export default ButtonIcon;
