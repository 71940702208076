import { createContext, useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import axios from 'axios';
import { Mixpanel } from '../helpers/mixpanel';
import { findSearchField } from '../helpers/search';
import { UIContext } from '../context/UIContext';
import searchFile from '../helpers/searchFile';

const SearchContext = createContext();

const SearchProvider = ({ children }) => {
    const router = useRouter();
    const [branchList, setBranchList] = useState(null);
    const [jobList, setJobList] = useState(null);
    const [branch, setBranch] = useState([]);
    const [job, setJob] = useState([]);
    const [employmentType, setEmploymentType] = useState([]);
    const [workLevel, setWorkLevel] = useState([]);
    const [city, setCity] = useState([]);
    const [remote, setRemote] = useState(false);
    const [openToMove, setOpenToMove] = useState(false);
    const [selectLocationPrefs, setSelectedLocationPrefs] = useState([]);
    const [jobLocations, setJobLocations] = useState(null);
    const [jobOptions, setJobOptions] = useState([]);
    const [smartSearch, setSmartSearch] = useState(true);

    const { toggleMobileSearch } = useContext(UIContext);

    useEffect(() => {
        async function fetchSearchData() {
            //get branch and jobs
            const {
                data: {
                    data: { branches, jobtitles },
                },
            } = await axios.get(`${process.env.STUDIO_WEB_API}/jobtitles/both`);

            //get job locations
            const {
                data: { data: joblocations },
            } = await axios.get(`${process.env.STUDIO_WEB_API}/joblocations`);

            //get type of search from user session
            const typeOfSearch = localStorage.getItem('typeOfSearch') ? JSON.parse(localStorage.getItem('typeOfSearch')) : true;

            setBranchList(branches);
            setJobList(jobtitles);
            setJobLocations(joblocations);
            setSmartSearch(typeOfSearch);
            setSearchListFromCookies(jobtitles);
        }

        // loadSearchParamsFromCookies();
        fetchSearchData();
    }, []);

    const setSearchListFromCookies = (jobtitles) => {
        //receive query and set fields
        const query = Cookies.get('otellu-job-search') && JSON.parse(Cookies.get('otellu-job-search'));

        if (query) {
            if (query.branch && query.branch.length !== 0) {
                setBranch(query.branch);
                const filterJobTitles = jobtitles.filter((job) => job.branch === query.branch._id);
                setJobOptions(filterJobTitles);
            }
            if (query.job && query.job.length !== 0) {
                setJob(query.job);
            }
            if (query.employmentType && query.employmentType.length !== 0) {
                setEmploymentType(query.employmentType);
            }

            if (query.workLevel && query.workLevel.length !== 0) {
                setWorkLevel(query.workLevel);
            }

            if (query.city && query.city.length !== 0) {
                setCity(query.city);
            }

            if (query.remote) {
                setRemote(query.remote);
            }

            if (query.openToMove) {
                setOpenToMove(query.openToMove);
            }
        }
    };

    const resetFields = () => {
        setBranch([]);
        setJob([]);
        setEmploymentType([]);
        setJob([]);
        return Cookies.remove('otellu-job-search');
    };

    const setField = (type, value) => {
        //set branch
        if (type === 'branch') {
            const filterJobTitles = value.length !== 0 && jobList.filter((job) => job.branch === value._id);
            setJobOptions(filterJobTitles);
            setBranch(value);
        }
        //set Job
        if (type === 'job') {
            setJob(value);
        }

        //set employmentType
        if (type === 'employmentType') {
            setEmploymentType(value);
        }

        //set workLevel
        if (type === 'workLevel') {
            setWorkLevel([value]);
        }

        //set workLevel
        if (type === 'city') {
            setCity(value);
        }
    };

    const removeField = (type, id) => {
        if (type === 'branch') {
            setBranch([]);
            setJob([]);
            setJobOptions(jobList);

            const searchCookies = setupSearchCookies(null, null, employmentType, workLevel, city, remote, openToMove);
            const inputs = setupMixpanelEvents(null, null, employmentType, workLevel, city, remote, openToMove);
            const params = generateLink(null, null, employmentType, workLevel, city, remote, openToMove);

            Mixpanel.track('Search job', {
                ...inputs,
            });

            Cookies.set('otellu-job-search', {
                ...searchCookies,
            });

            return router.push({
                pathname: '/s',
                query: {
                    ...params,
                },
            });
        }
        if (type === 'job') {
            //check if multiple jobs are selected than remove just one based on jobtitle ID
            if (job.length > 1) {
                const selectedJobTitles = job.filter((x) => x._id === id);
                setJob(selectedJobTitles);
                const searchCookies = setupSearchCookies(branch, selectedJobTitles, employmentType, workLevel, city, remote, openToMove);
                const inputs = setupMixpanelEvents(branch, selectedJobTitles, employmentType, workLevel, city, remote, openToMove);
                const params = generateLink(branch, selectedJobTitles, employmentType, workLevel, city, remote, openToMove);

                Mixpanel.track('Search job', {
                    ...inputs,
                });

                Cookies.set('otellu-job-search', {
                    ...searchCookies,
                });

                return router.push({
                    pathname: '/s',
                    query: {
                        ...params,
                    },
                });
            }

            //Check if there only 1 jobtitle is selected
            if (job.length === 1) {
                setJob([]);
                const searchCookies = setupSearchCookies(branch, null, employmentType, workLevel, city, remote, openToMove);
                const inputs = setupMixpanelEvents(branch, null, employmentType, workLevel, city, remote, openToMove);
                const params = generateLink(branch, null, employmentType, workLevel, city, remote, openToMove);

                Mixpanel.track('Search job', {
                    ...inputs,
                });

                Cookies.set('otellu-job-search', {
                    ...searchCookies,
                });

                return router.push({
                    pathname: '/s',
                    query: {
                        ...params,
                    },
                });
            }
        }
        if (type === 'employmentType') {
            setEmploymentType([]);

            const searchCookies = setupSearchCookies(branch, job, null, workLevel, city, remote, openToMove);
            const inputs = setupMixpanelEvents(branch, job, null, workLevel, city, remote, openToMove);
            const params = generateLink(branch, job, null, workLevel, city, remote, openToMove);

            Mixpanel.track('Search job', {
                ...inputs,
            });

            Cookies.set('otellu-job-search', {
                ...searchCookies,
            });

            return router.push({
                pathname: '/s',
                query: {
                    ...params,
                },
            });
        }

        if (type === 'workLevel') {
            const updatedField = workLevel.filter((item) => item.id !== id);
            setWorkLevel(updatedField);

            const searchCookies = setupSearchCookies(branch, job, employmentType, null, city, remote, openToMove);
            const inputs = setupMixpanelEvents(branch, job, employmentType, null, city, remote, openToMove);
            const params = generateLink(branch, job, employmentType, null, city, remote, openToMove);

            Mixpanel.track('Search job', {
                ...inputs,
            });

            Cookies.set('otellu-job-search', {
                ...searchCookies,
            });

            return router.push({
                pathname: '/s',
                query: {
                    ...params,
                },
            });
        }

        if (type === 'city') {
            setCity([]);

            const searchCookies = setupSearchCookies(branch, job, employmentType, workLevel, null, remote, openToMove);
            const inputs = setupMixpanelEvents(branch, job, employmentType, workLevel, null, remote, openToMove);
            const params = generateLink(branch, job, employmentType, workLevel, null, remote, openToMove);

            Mixpanel.track('Search job', {
                ...inputs,
            });

            Cookies.set('otellu-job-search', {
                ...searchCookies,
            });

            return router.push({
                pathname: '/s',
                query: {
                    ...params,
                },
            });
        }

        if (type === 'remote') {
            setRemote(false);

            const searchCookies = setupSearchCookies(branch, job, employmentType, workLevel, city, null, openToMove);
            const inputs = setupMixpanelEvents(branch, job, employmentType, workLevel, city, null, openToMove);
            const params = generateLink(branch, job, employmentType, workLevel, city, null, openToMove);

            Mixpanel.track('Search job', {
                ...inputs,
            });

            Cookies.set('otellu-job-search', {
                ...searchCookies,
            });

            return router.push({
                pathname: '/s',
                query: {
                    ...params,
                },
            });
        }

        if (type === 'openToMove') {
            setOpenToMove(false);

            const searchCookies = setupSearchCookies(branch, job, employmentType, workLevel, city, remote, null);
            const inputs = setupMixpanelEvents(branch, job, employmentType, workLevel, city, remote, null);
            const params = generateLink(branch, job, employmentType, workLevel, city, remote, null);

            Mixpanel.track('Search job', {
                ...inputs,
            });

            Cookies.set('otellu-job-search', {
                ...searchCookies,
            });

            return router.push({
                pathname: '/s',
                query: {
                    ...params,
                },
            });
        }
    };

    const setQuery = (type, value) => {
        toggleMobileSearch();

        //set branch
        if (type === 'branch') {
            //if branch changed while job already defined should clear old selected jobtitles
            if (branch.name && job.length !== 0) {
                const filterJobTitles = value.length !== 0 && jobList.filter((job) => job.branch === value._id);
                setJob([]);
                setJobOptions(filterJobTitles);
                setBranch(value);

                const searchCookies = setupSearchCookies(value, null, employmentType, workLevel, city, remote, openToMove);
                const inputs = setupMixpanelEvents(value, null, employmentType, workLevel, city, remote, openToMove);
                const params = generateLink(value, null, employmentType, workLevel, city, remote, openToMove);

                Mixpanel.track('Search job', {
                    ...inputs,
                });

                Cookies.set('otellu-job-search', {
                    ...searchCookies,
                });

                return router.push({
                    pathname: '/s',
                    query: {
                        ...params,
                    },
                });
            }
            //if branch changed while job is not filled -> filter related job titles
            if (branch.name && !job.enum) {
                const filterJobTitles = value.length !== 0 && jobList.filter((job) => job.branch === value._id);
                setJobOptions(filterJobTitles);
                setBranch(value);

                const searchCookies = setupSearchCookies(value, job, employmentType, workLevel, city, remote, openToMove);
                const inputs = setupMixpanelEvents(value, job, employmentType, workLevel, city, remote, openToMove);
                const params = generateLink(value, job, employmentType, workLevel, city, remote, openToMove);

                Mixpanel.track('Search job', {
                    ...inputs,
                });

                Cookies.set('otellu-job-search', {
                    ...searchCookies,
                });

                return router.push({
                    pathname: '/s',
                    query: {
                        ...params,
                    },
                });
            }

            //if branch is not filled in
            if (!branch.name) {
                const filterJobTitles = value.length !== 0 && jobList.filter((job) => job.branch === value._id);
                setJobOptions(filterJobTitles);
                setBranch(value);

                const searchCookies = setupSearchCookies(value, job, employmentType, workLevel, city, remote, openToMove);
                const inputs = setupMixpanelEvents(value, job, employmentType, workLevel, city, remote, openToMove);
                const params = generateLink(value, job, employmentType, workLevel, city, remote, openToMove);

                Mixpanel.track('Search job', {
                    ...inputs,
                });

                Cookies.set('otellu-job-search', {
                    ...searchCookies,
                });

                return router.push({
                    pathname: '/s',
                    query: {
                        ...params,
                    },
                });
            }
        }
        //set Job
        if (type === 'job') {
            let params;
            if (value === null) {
                params = generateLink(branch, [], employmentType, workLevel, city, remote, openToMove);
                setJob([]);
            }
            if (value !== null) {
                params = generateLink(branch, value, employmentType, workLevel, city, remote, openToMove);
                setJob(value);
            }

            const searchCookies = setupSearchCookies(branch, value, employmentType, workLevel, city, remote, openToMove);
            const inputs = setupMixpanelEvents(branch, value, employmentType, workLevel, city, remote, openToMove);

            Mixpanel.track('Search job', {
                ...inputs,
            });

            Cookies.set('otellu-job-search', {
                ...searchCookies,
            });

            return router.push({
                pathname: '/s',
                query: {
                    ...params,
                },
            });
        }

        //set workLevel
        if (type === 'workLevel') {
            setWorkLevel((prevState) => [...prevState, { ...value }]);
            workLevel[workLevel.length] = value;

            const searchCookies = setupSearchCookies(branch, job, employmentType, workLevel, city, remote, openToMove);
            const inputs = setupMixpanelEvents(branch, job, employmentType, workLevel, city, remote, openToMove);
            const params = generateLink(branch, job, employmentType, workLevel, city, remote, openToMove);

            Mixpanel.track('Search job', {
                ...inputs,
            });

            Cookies.set('otellu-job-search', {
                ...searchCookies,
            });

            return router.push({
                pathname: '/s',
                query: {
                    ...params,
                },
            });
        }

        //set employmentType
        if (type === 'employmentType') {
            setEmploymentType(value);

            const searchCookies = setupSearchCookies(branch, job, value, workLevel, city, remote, openToMove);
            const inputs = setupMixpanelEvents(branch, job, value, workLevel, city, remote, openToMove);
            const params = generateLink(branch, job, value, workLevel, city, remote, openToMove);

            Mixpanel.track('Search job', {
                ...inputs,
            });

            Cookies.set('otellu-job-search', {
                ...searchCookies,
            });

            return router.push({
                pathname: '/s',
                query: {
                    ...params,
                },
            });
        }

        //set city
        if (type === 'city') {
            setCity(value);

            const searchCookies = setupSearchCookies(branch, job, employmentType, workLevel, value, remote, openToMove);
            const inputs = setupMixpanelEvents(branch, job, employmentType, workLevel, value, remote, openToMove);
            const params = generateLink(branch, job, employmentType, workLevel, value, remote, openToMove);

            Mixpanel.track('Search job', {
                ...inputs,
            });

            Cookies.set('otellu-job-search', {
                ...searchCookies,
            });

            return router.push({
                pathname: '/s',
                query: {
                    ...params,
                },
            });
        }

        //set remote
        if (type === 'remote') {
            setRemote(true);

            const searchCookies = setupSearchCookies(branch, job, employmentType, workLevel, city, value, openToMove);
            const inputs = setupMixpanelEvents(branch, job, employmentType, workLevel, city, value, openToMove);
            const params = generateLink(branch, job, employmentType, workLevel, city, value, openToMove);

            Mixpanel.track('Search job', {
                ...inputs,
            });

            Cookies.set('otellu-job-search', {
                ...searchCookies,
            });

            return router.push({
                pathname: '/s',
                query: {
                    ...params,
                },
            });
        }

        //set openToMove
        if (type === 'openToMove') {
            setOpenToMove(true);

            const searchCookies = setupSearchCookies(branch, job, employmentType, workLevel, city, remote, value);
            const inputs = setupMixpanelEvents(branch, job, employmentType, workLevel, city, remote, value);
            const params = generateLink(branch, job, employmentType, workLevel, city, remote, value);

            Mixpanel.track('Search job', {
                ...inputs,
            });

            Cookies.set('otellu-job-search', {
                ...searchCookies,
            });

            return router.push({
                pathname: '/s',
                query: {
                    ...params,
                },
            });
        }
    };

    const setTypeOfSearch = (type) => {
        const params = generateLink(branch, job, employmentType, workLevel, city, remote, openToMove, type);
        localStorage.setItem('typeOfSearch', type);
        setSmartSearch(type);

        return router.push({
            pathname: '/s',
            query: {
                ...params,
            },
        });
    };

    const goBackToSearch = () => {
        const query = Cookies.get('otellu-job-search') && JSON.parse(Cookies.get('otellu-job-search'));
        if (!query) {
            return router.push({
                pathname: '/',
            });
        }

        if (query) {
            const params = generateLink(
                query.branch ? query.branch : null,
                query.job ? query.job : null,
                query.employmentType ? query.employmentType : null,
                query.workLevel ? query.workLevel : null,
                query.city ? query.city : null
            );

            return router.push({
                pathname: '/s',
                query: {
                    ...params,
                },
            });
        }
    };

    const generateLink = (branch, job, employmentType, workLevel, city, remote, openToMove, typeOfSmartSearch) => {
        let params = {};
        if (branch !== null && branch.name) {
            params.branch = branch.enum;
        }
        if (job !== null && job.length !== 0) {
            params.job = job.map((x) => x.enum).join();
        }
        if (employmentType !== null && employmentType.name) {
            params.employmentType = employmentType.name;
        }
        if (workLevel !== null && workLevel.length !== 0) {
            params.workLevel = workLevel.map((x) => x.name).join();
        }

        if (city !== null && city.name) {
            params.city = city.name;
        }

        if (remote) {
            params.remote = 'True';
        }

        if (openToMove) {
            params.openToMove = 'True';
        }

        if (typeOfSmartSearch === undefined) {
            !smartSearch ? (params.type = 'logical') : null;
        }

        if (typeOfSmartSearch !== undefined) {
            !typeOfSmartSearch ? (params.type = 'logical') : null;
        }

        return params;
    };

    const setupMixpanelEvents = (branch, job, employmentType, workLevel, city, remote, openToMove) => {
        let params = {};

        if (branch !== null && branch.name) {
            params.branch = branch.name;
        }
        if (job !== null && job.length !== 0) {
            params.job = job.map((x) => x.humanReadable);
        }
        if (employmentType !== null && employmentType.name) {
            params.employmentType = employmentType.name;
        }
        if (workLevel !== null && workLevel.length !== 0) {
            params.workLevel = workLevel.map((x) => x.name);
        }

        if (city !== null && city.name) {
            params.city = city.name;
        }
        if (remote) {
            params.remote = remote;
        }

        if (openToMove) {
            params.openToMove = openToMove;
        }
        return params;
    };

    const setupSearchCookies = (branch, job, employmentType, workLevel, city, remote, openToMove) => {
        let params = {};

        if (branch !== null && branch.name) {
            params.branch = branch;
        }
        if (job !== null && job.length !== 0) {
            params.job = job;
        }
        if (employmentType !== null && employmentType.name) {
            params.employmentType = employmentType;
        }
        if (workLevel !== null && workLevel.length !== 0) {
            params.workLevel = workLevel;
        }

        if (city !== null && city.name) {
            params.city = city;
        }

        if (remote) {
            params.remote = remote;
        }

        if (openToMove) {
            params.openToMove = openToMove;
        }

        return params;
    };

    return (
        <SearchContext.Provider
            value={{
                branchList,
                jobOptions,
                jobList,
                jobLocations,
                setField,
                branch,
                job,
                employmentType,
                workLevel,
                city,
                remote,
                openToMove,
                selectLocationPrefs,
                setQuery,
                resetFields,
                removeField,
                smartSearch,
                setSmartSearch,
                setJobOptions,
                setTypeOfSearch,
                goBackToSearch,
            }}
        >
            {children}
        </SearchContext.Provider>
    );
};

export { SearchProvider, SearchContext };
