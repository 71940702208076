import React from 'react';

const RadioInput = (props) => (
    <div className="flex items-center justify-between w-12">
        <p className="mr-1 text-sm text-gray-500">{props.label}</p>
        <input onChange={props.onChange} name={props.name} value={props.value} required={props.required} className="h-4 w-4" type="radio" {...props} />
    </div>
);

export default RadioInput;
