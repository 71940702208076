import React, { useContext, useState } from 'react';
import ReactCodeInput from 'react-verification-code-input';
import { AuthContext } from '../../../context/AuthContext';

const VerifyForm = (props) => {
    const [verifyCode, setVerifycode] = useState('');
    const { register, handleSubmit, location, currentApplyIdx, stepIdx } = props;
    const { applyNavigation, verifyAccount } = useContext(AuthContext);
    if (location === 'apply' && currentApplyIdx !== stepIdx) {
        return null;
    }

    return (
        <div className="flex flex-col justify-center py-8 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-lg">
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Verify your account</h2>
                <p className="mt-2 text-center text-base text-gray-600 max-w">
                    Check your e-mail! You received a verification code. The code will look like this (038-114)
                </p>
            </div>

            <div className="sm:mx-auto sm:w-full sm:max-w-xl">
                <div className="bg-white py-8 px-4 sm:rounded-lg sm:px-10">
                    <div className="space-y-6 mx-auto">
                        <div className="flex justify-center">
                            <ReactCodeInput fieldWidth={50} fieldHeight={50} onComplete={(e) => verifyAccount(e, location)} className="border-red-600 w-20" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerifyForm;
