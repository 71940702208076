import React, { useContext } from 'react';
import { Label, Input, CodeInput, Select, Pill } from '../../Atoms/Form';
import { Button } from '../../Atoms/Elements';
import useAuth from '../../../hooks/useAuth';
import { AuthContext } from '../../../context/AuthContext';

const VerifyForm = (props) => {
    const { values, loading, onChange, userEmail } = props;
    const { handleResendVerifyRequest } = useAuth();
    const { user } = useContext(AuthContext);
    return (
        <div>
            <div className="space-y-4 mx-auto flex justify-center">
                <CodeInput values={values.v_code} onChange={onChange} />
            </div>
            <div className="mt-4">
                <Button data_cy="button-verify" type="submit" colorScheme="default" size="lg" loading={loading}>
                    Verify account
                </Button>
            </div>
            <div className="mt-4">
                <p
                    onClick={async () => await handleResendVerifyRequest({ email: userEmail, toastNotify: true })}
                    className="text-sm text-gray-600 underline cursor-pointer text-center"
                >
                    Can't find verify code? Resend
                </p>
            </div>
        </div>
    );
};

export default VerifyForm;
