import { createContext, useState, useEffect } from 'react';
import Router from 'next/router';
import axios from 'axios';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import { Mixpanel } from '../helpers/mixpanel';
import { uo_url, wo_url } from '../helpers/url';
import { calculateApplySteps } from '../helpers/apply';
import { JobContext } from '../context/JobContext';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    //auth states
    const [user, setUser] = useState(null);
    const [initialUserFetching, setInitialUserFetching] = useState(false);
    const [verifyEmail, setVerifyEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [takeAuthAction, setTakeAuthAction] = useState(false);
    const [authStep, setAuthStep] = useState('register');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [savedJobs, setSavedJobs] = useState(null);

    //match states
    const [matches, setMatches] = useState([]);

    //apply states
    const [currentApplyIdx, setCurrentApplyIdx] = useState(1);
    const [addons, setAddons] = useState([]);
    const [applicationInfo, setApplicationInfo] = useState([]);

    const [applyStage, setApplyStage] = useState('applicationForm');
    const [applySteps, setApplySteps] = useState([]);
    const [applyAuthStage, setAuthApplyStage] = useState('login');
    const [applyLoading, setApplyLoading] = useState(false);
    const [applyMessage, setApplyMessage] = useState('');
    const [applyError, setApplyError] = useState(false);
    const [applyLoadingState, setApplyLoadingState] = useState({
        loading: false,
        done: false,
    });

    useEffect(() => {
        async function loadUserFromCookies() {
            const token = Cookies.get('otellu-people-jwt-token');
            if (token) {
                setInitialUserFetching(true);
                try {
                    const response = await axios.post(`${uo_url}/users/profile`, { token });
                    const { user } = response.data.data;
                    setInitialUserFetching(false);
                    return setUser(user);
                } catch (err) {
                    setInitialUserFetching(false);
                    return setUser(null);
                }
            }
        }
        loadUserFromCookies();
    }, [setUser]);

    const initializeAuth = () => {
        setLoading(false);
        setMessage('');
        setError(false);
    };

    const settingUser = (user) => {
        setUser(user);
    };

    //AUTH STATE
    const authenticate = async (userInfo, page) => {
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.PEOPLE_WEB_API}/users/authentication`, userInfo);
            const { token, user } = response.data;
            setUser(user);
            setLoading(false);
            setIsAuthenticated(true);

            Cookies.set('otellu-people-jwt-token', token);
            toast.success(`Welcome back ${user.firstName}!`);

            Mixpanel.identify(user._id);
            Mixpanel.track('Login', { page: 'login page' });
            Mixpanel.people.set({
                $first_name: user.firstName,
                $last_name: user.lastName,
                $email: user.email,
            });
            if (page === 'external_job') {
                setTakeAuthAction(true);
            }

            if (page === 'apply') {
                return setApplyStage('submitApplication');
            }

            if (page === 'login') {
                return Router.push('/profile');
            }
        } catch (err) {
            const { message } = err.response.data;
            setMessage(message);
            setError(true);
            setLoading(false);
        }
    };

    const createAccount = async (user, page) => {
        setLoading(true);
        try {
            const signup = await axios.post(`${uo_url}/users/registration`, user);
            const { data } = signup.data;
            setLoading(false);
            setError(false);
            setVerifyEmail(user.email);
            toast.success(`Welcome ${user.firstName}!`);

            Mixpanel.identify(data._id);
            Mixpanel.track('Signup', { page: page });
            Mixpanel.people.set({
                $first_name: data.firstName,
                $last_name: data.lastName,
                $email: data.email,
            });

            if (page === 'external_job') {
                setAuthStep('verify');
            }
            if (page === 'apply') {
                return setApplyStage('VerifyForm');
            }
            if (page === 'register') {
                return Router.push(`auth/verify?email=${user.email}`);
            }
        } catch (err) {
            const { message } = err.response.data;
            setMessage(message);
            setError(true);
            setLoading(false);
        }
    };

    const verifyAccount = async (verify_code, page) => {
        const info = {
            email: verifyEmail,
            v_code: verify_code,
        };
        setLoading(true);
        try {
            const response = await axios.post(`${uo_url}/users/registration/verify`, info);
            const { user, token } = response.data.data;
            setUser(user);
            setLoading(false);
            setError(false);
            Cookies.set('otellu-people-jwt-token', token);
            toast.success('Your account has been verified!');

            if (page === 'external_job') {
                setTakeAuthAction(true);
            }
            if (page === 'apply') {
                // setCurrentApplyIdx(currentApplyIdx);
                setAuthApplyStage('onboarding');
            }
            if (page === 'verify') {
                return Router.push('/onboarding');
            }
        } catch (err) {
            const { message } = err.response.data;
            setError(true);
            setMessage(message);
            setLoading(false);
        }
    };

    const forgotPassword = async (d) => {
        setLoading(true);
        try {
            const response = await axios.post(`${uo_url}/users/authentication/forgot-password`, d);
            const { message } = response.data;
            setUser(user);
            setLoading(false);
            setError(false);
            setMessage(message);
        } catch (err) {
            const { message } = err.response.data;
            setError(true);
            setMessage(message);
            setLoading(false);
        }
    };

    const resetPassword = async (d, token) => {
        var fields = token.split('-');
        var jwtToken = fields[0].replace(new RegExp('slash', 'g'), '/');
        var id = fields[1];
        const info = {
            token: jwtToken,
            id,
            password: d.password,
        };
        setLoading(true);
        try {
            const response = await axios.post(`${uo_url}/users/authentication/reset-password`, info);
            const { message } = response.data;
            setUser(user);
            setLoading(false);
            setError(false);
            setMessage(message);
        } catch (err) {
            const { message } = err.response.data;
            setError(true);
            setMessage(message);
            setLoading(false);
        }
    };

    const deleteUser = async () => {
        setLoading(true);
        try {
            await axios.delete(`${uo_url}/users/${user._id}`);
            setLoading(false);
            setError(false);
            setUser(null);
            Cookies.remove('otellu-people-jwt-token');
            toast.success('Your account has been deleted succesfully!');
            return Router.push(`/login`);
        } catch (err) {
            const { message } = err.response.data;
            setError(true);
            setMessage(message);
            setLoading(false);
        }
    };

    const getSavedJobs = async (userId) => {
        setFetching(true);
        try {
            setFetching(false);
            const response = await axios.get(`${uo_url}/users/jobs/${userId}`);
            setSavedJobs(response.data.data);
        } catch (err) {
            setFetching(false);
            setSavedJobs(null);
            return err;
        }
    };

    const removeSavedJob = async (jobId) => {
        const ext = { type: 'external', jobs: savedJobs[0].jobs.filter((item) => item.id !== jobId) };
        const int = { type: 'otellu', jobs: savedJobs[1].jobs.filter((item) => item._id !== jobId) };
        setSavedJobs([ext, int]);
    };

    const logoutUser = async () => {
        Cookies.remove('otellu-people-jwt-token');
        setUser(null);
        return Router.push('/login');
    };

    const updateUser = async (user) => {
        try {
            const response = await axios.put(`${uo_url}/users`, user);
            const { message, data } = response.data;
            setUser(data);
            toast.success(message);
            return response;
        } catch (err) {
            return err;
        }
    };

    //MATCH STATE MANAGEMENT

    const getMatches = async (userId) => {
        //get matches from user
        const response = await axios.get(`${uo_url}/users/match/${userId}`);
        const { otelluMatches, airtableMatches } = response.data;

        // get actual otellu jobs from matchIds
        const otelluJobsReq = await Promise.all(
            otelluMatches.map(async (x) => ({
                matchCreatedAt: x.createdAt,
                type: 'OTELLU',
                job: await axios.get(`${uo_url}/job/otellu/${x.vacancyId}`),
                _id: x._id,
            }))
        );

        //get actual external jobs from matchIds
        const externalJobsReq = await Promise.all(
            airtableMatches &&
                airtableMatches.map(async (x) => ({
                    matchCreatedAt: x.createdAt,
                    type: 'EXTERNAL',
                    job: await axios.get(`${uo_url}/job/external/${x.vacancyId}`),
                    _id: x._id,
                }))
        );
        // const otelluJobs = otelluJobsReq.map((x) => ({ matchCreatedAt: x.matchCreatedAt, job: x.job.data, type: x.type, _id: x._id }));
        const externalJobs = externalJobsReq.map((x) => ({ matchCreatedAt: x.matchCreatedAt, job: x.job.data, type: x.type, _id: x._id }));

        // //merge jobs in one array
        const matches = [...otelluMatches, ...externalJobs];
        setMatches(matches);
    };

    const updateMatches = async (matches) => {
        const otelluMatches = matches.filter((x) => x.type === 'OTELLU').map((x) => x._id);
        const airtableMatches = matches.filter((x) => x.type === 'EXTERNAL').map((x) => x._id);
        await axios.put(`${uo_url}/match`, {
            otelluMatches,
            airtableMatches,
        });
    };

    const deleteOtelluMatch = async (id) => {
        try {
            await axios.delete(`${uo_url}/match/otellu/${id}`);
            setMatches(matches.filter((item) => item._id !== id));
            return toast.success(`${user.firstName}, you succesfully deleted a match!`);
        } catch (err) {
            return toast.error(`${user.firstName}, something went wrong deleting a match!`);
        }
    };

    const deleteAirtableMatch = async (id) => {
        try {
            await axios.delete(`${uo_url}/match/airtable/${id}`);
            setMatches(matches.filter((item) => item._id !== id));
            return toast.success(`${user.firstName}, you succesfully deleted a match!`);
        } catch (err) {
            return toast.error(`${user.firstName}, something went wrong deleting a match!`);
        }
    };

    //----------APPLY STATE MANAGEMENT--------------

    const generateApplySteps = (vacancy, user) => {
        const { steps, addons } = calculateApplySteps(vacancy, user);
        setApplySteps(steps);
        setAddons(addons.map(({ isAddon, title, visible, stepIdx, ...keepAttrs }) => keepAttrs));
    };

    const refreshApply = () => {
        setApplyLoadingState({
            loading: false,
            done: false,
        });
        setCurrentApplyIdx(1);
    };

    const handleAuth = async (d, location, type) => {
        if (type === 'login') {
            setLoading(true);
            try {
                const response = await axios.post(`${process.env.PEOPLE_WEB_API}/users/authentication`, d);
                const { token, user } = response.data;
                setUser(user);
                setLoading(false);
                setCurrentApplyIdx(currentApplyIdx);
                Cookies.set('otellu-people-jwt-token', token);

                Mixpanel.identify(user._id);
                Mixpanel.track('Login', { page: 'apply page' });
                Mixpanel.people.set({
                    $first_name: user.firstName,
                    $last_name: user.lastName,
                    $email: user.email,
                });
                toast.success(`Welcome back ${user.firstName}!`);
            } catch (err) {
                const { message } = err.response.data;
                setMessage(message);
                setError(true);
                setLoading(false);
            }
        }
        if (type === 'register') {
            setLoading(true);
            try {
                const signup = await axios.post(`${uo_url}/users/registration`, d);
                const { data } = signup.data;

                setLoading(false);
                setError(false);
                setVerifyEmail(data.email);
                toast.success(`Welcome ${data.firstName}!`);
                setAuthApplyStage('verify');
                Mixpanel.identify(data._id);
                Mixpanel.track('Signup', { page: 'apply page' });
                Mixpanel.people.set({
                    $first_name: data.firstName,
                    $last_name: data.lastName,
                    $email: data.email,
                });
                return setAuthApplyStage('verify');
            } catch (err) {
                const { message } = err.response.data;
                setMessage(message);
                setError(true);
                setLoading(false);
            }
        }
    };

    const initializeAddons = (addons) => {
        setAddons(addons);
    };

    const updateAddon = (addonId, data, action) => {
        if (action === 'update') {
            const newAddons = [...addons];
            const addonIdx = addons.findIndex((x) => x.addonId === addonId);
            newAddons[addonIdx].data = data;
            setAddons(newAddons);
        }

        setCurrentApplyIdx(currentApplyIdx + 1);
    };
    const applyNavigation = (type) => {
        if (type === 'previous') {
            setCurrentApplyIdx(currentApplyIdx - 1);
        }
        if (type === 'next') {
            setCurrentApplyIdx(currentApplyIdx + 1);
        }
    };

    const submitApplicationForm = async (data, vacancy) => {
        setApplicationInfo(data);
        setCurrentApplyIdx(currentApplyIdx + 1);

        Mixpanel.track('Submit Info Apply', { vacancyId: vacancy._id, vacancy: vacancy });
    };

    const submitSoftskillsForm = () => {
        setCurrentApplyIdx(currentApplyIdx + 1);
        if (user === null) {
            return setApplyStage('LoginForm');
        }
        if (user !== null) {
            setApplyStage('submitApplication');
        }
    };

    const submitExtraQuestionsForm = (answers) => {
        const answers_arr = Object.keys(answers);
        const addonIdx = addons.findIndex((x) => x.name === 'Questions');
        const updatedAddons = [...addons];

        const updatedData = addons[addonIdx].data.questions.map((obj, index) =>
            index === parseInt(answers_arr[index]) ? { ...obj, answer: answers[index] } : obj
        );
        updatedAddons[addonIdx].data.questions = updatedData;
        setAddons(updatedAddons);
        setCurrentApplyIdx(currentApplyIdx + 1);
    };

    //prepare file for api call
    const prepareResumefile = (user, file) => {
        var bodyFormData = new FormData();
        bodyFormData.set('userId', user._id);
        bodyFormData.append('file', file);
        return bodyFormData;
    };

    //generate body object for application api request
    const generateApplicationBody = (user, info, vacancy) => {
        return {
            vacancyId: vacancy._id,
            groupId: vacancy.groupId,
            vacancyTitle: vacancy.title,
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            phone: info.phone,
            motivation: info.motivation,
            skills: user.skills,
            applicantId: user._id,
            skills: user.skills,
            preferences: user.preferences,
            addonData: addons,
            refCode: Cookies.get('otellu-ref') ? Cookies.get('otellu-ref') : '',
            specifications: {
                jobTitle: vacancy.jobTitle,
                branch: vacancy.branch,
                workLevel: info.workLevel.name,
                employmentType: info.employmentType.name,
            },
        };
    };

    //submitting application
    const submitApplication = async (vacancy) => {
        setApplyLoading(true);
        setApplyLoadingState({
            loading: true,
            done: false,
        });
        try {
            const bodyFile = prepareResumefile(user, applicationInfo.resume[0]);
            const response_file = await axios({
                method: 'post',
                url: `${uo_url}/users/upload-resume`,
                data: bodyFile,
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            if (response_file.status === 200) {
                const body = generateApplicationBody(user, applicationInfo, vacancy);
                const response = await axios.post(`${wo_url}/applications/vacancy/${vacancy._id}`, body);
                updateUser(user);
                const { message } = response.data;
                setApplyMessage(message);
                setApplyLoading(false);
                setApplyError(false);
                setApplyLoadingState({
                    loading: false,
                    done: true,
                });
            }

            Mixpanel.track('Submit Apply', { vacancyId: vacancy._id, vacancy: vacancy, type: 'internal' });

            //track ads conversion
            if (typeof window !== 'undefined') {
                if (window.gtag != null) {
                    window.gtag('event', 'conversion', { send_to: 'AW-631299722/sEfQCIeHp_QCEIq9g60C' });
                }
            }
        } catch (err) {
            setApplyError(true);
            setApplyLoading(false);
            setApplyLoadingState({
                loading: false,
                done: true,
            });
        }
    };

    return (
        <AuthContext.Provider
            value={{
                //Auth functions and state
                settingUser,
                initializeAuth,
                user,
                savedJobs,
                authenticate,
                createAccount,
                verifyAccount,
                forgotPassword,
                resetPassword,
                deleteUser,
                getSavedJobs,
                removeSavedJob,
                logoutUser,
                updateUser,
                takeAuthAction,
                authStep,
                setAuthStep,
                setTakeAuthAction,
                isAuthenticated,

                //Match functions and state
                getMatches,
                deleteOtelluMatch,
                deleteAirtableMatch,
                updateMatches,
                matches,

                //Apply functions and state
                applySteps,
                generateApplySteps,
                handleAuth,
                applyAuthStage,
                setAuthApplyStage,
                currentApplyIdx,
                setCurrentApplyIdx,
                initializeAddons,
                addons,
                updateAddon,
                submitApplicationForm,
                submitSoftskillsForm,
                submitExtraQuestionsForm,
                submitApplication,
                applyNavigation,
                applyStage,
                applicationInfo,
                loading,
                fetching,
                message,
                error,
                applyLoading,
                applyMessage,
                applyError,
                applyLoadingState,
                refreshApply,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export { AuthProvider, AuthContext };
