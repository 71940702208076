import React from 'react';
import { default as SelectInput } from 'react-select';

const Select = (props) => {
    const { options, value, name, placeholder, isError, onChange, onBlur, id } = props;
    const errorStyles = {
        control: (provided, state) => ({
            ...provided,
            border: isError ? '1px solid #f87071' : '1px solid #cccccc',
            background: isError ? '#fde2e2' : '#ffffff',
        }),
    };
    return (
        <SelectInput
            id={id}
            name={name}
            value={value}
            placeholder={placeholder}
            styles={errorStyles}
            onChange={onChange}
            onBlur={onBlur}
            options={options}
            getOptionValue={(option) => option.humanReadable || option.name || option.label}
            getOptionLabel={(option) => option.humanReadable || option.name || option.label}
            isSearchable={true}
        />
    );
};

export default Select;
