import React from 'react';

const Input = (props) => {
    const { onChange, name, type, placeholder, required, data_cy } = props;
    return (
        <input
            data-cy={data_cy}
            type={type}
            name={name}
            required={required}
            id={name}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder={placeholder}
            onChange={onChange}
        />
    );
};

export default Input;
