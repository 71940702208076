import React from 'react';
import { Spinner } from '../Animations';

const Button = (props) => {
    const { children, type, onClick, colorScheme, size, loading, data_cy } = props;
    const defaultStyle = 'w-full flex justify-center rounded-md';
    const sizes = {
        xs: 'text-xs px-2.5 py-1.5',
        sm: 'text-sm px-3 py-2',
        md: 'text-xs px-4 py-2',
        lg: 'text-base px-4 py-2',
        xl: 'text-base px-6 py-3',
    };
    const color = {
        default: 'text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
        purple: 'text-white bg-purple-800 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500',
        whiteOutline: 'text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 ',
    };
    return (
        <button data-cy={data_cy} type={type} onClick={onClick} className={`${color[colorScheme]} ${sizes[size]} ${defaultStyle}`}>
            {loading ? <Spinner /> : children}
        </button>
    );
};

export default Button;
