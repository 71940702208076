export const calculateApplySteps = (vacancy, user) => {
    //get addons
    const addonsState = [...vacancy.addonData];
    let count = 0;
    const addons = [];
    for (var i = 0; i < addonsState.length; ++i) {
        if (addonsState[i].name == 'Soft Skills') {
            count++;
            addons.push({
                ...addonsState[i],
                title: addonsState[i].name,
                stepIdx: count + 1,
                data: { ...addonsState[i].data, applicantTypeFormToken: generateTypeformToken() },
                isAddon: true,
                visible: true,
            });
        }
        if (addonsState[i].name == 'Questions') {
            count++;
            addons.push({
                ...addonsState[i],
                title: addonsState[i].name,
                stepIdx: count + 1,
                visible: true,
                isAddon: true,
            });
        }
    }

    //create steps
    const steps = [
        {
            title: 'Application Information',
            name: 'x',
            stage: 'applicationForm',
            stepIdx: 1,
            isAddon: false,
            visible: true,
        },
        ...addons,

        //Add step if user is not logged in
        user === null
            ? {
                  title: 'Auth form',
                  name: 'Auth',
                  stepIdx: 2 + addons.length,
                  isAddon: false,
                  visible: false,
              }
            : false,
        {
            title: 'Submit Application',
            name: 'Submit Application',

            //calculate last step of apply flow based on addons and auth
            stepIdx: (user === null ? 1 : 0) + (2 + addons.length),
            isAddon: false,
            visible: true,
        },
    ].filter((x) => x);
    return { steps, addons };
};

export const generateTypeformToken = () => {
    return Math.random().toString(36).substr(2);
};
