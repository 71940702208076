import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Label, Select, Pill } from '../../Atoms/Form';
import { Button } from '../../Atoms/Elements';

const SkillsForm = (props) => {
    const { handlePrevious, recommendedSkills, selectSkill, selectedSkills, addSkill, removeSkill } = props;
    const [allSkills, setAllSkills] = useState([]);

    useEffect(() => {
        async function fetchSkills() {
            try {
                const { data } = await axios.get(`${process.env.STUDIO_WEB_API}/skills`);
                setAllSkills(data);
            } catch (err) {
                setAllSkills([]);
            }
        }

        fetchSkills();
    }, []);

    const handleAddSkill = (value) => {
        setAllSkills(allSkills.filter(({ _id }) => _id !== value._id));
        addSkill(value);
    };

    return (
        <div>
            <div className="">
                <div className="flex flex-wrap gap-2 max-w-lg py-8 px-4 bg-gray-100 rounded-lg">
                    {recommendedSkills &&
                        recommendedSkills.map((x) => (
                            <Pill
                                data_cy="pill-skill"
                                key={x.value}
                                onClick={() => selectSkill(x)}
                                onRemove={() => removeSkill(x)}
                                selected={selectedSkills.filter((e) => e._id === x._id).length > 0}
                            >
                                {x.label}
                            </Pill>
                        ))}
                </div>
                <div className="mt-1">
                    <p className="text-sm text-gray-600">{`Currently selected: ${selectedSkills.map((x) => x.label)}`}</p>
                </div>

                <div className="mt-8">
                    <Label>Search for more skills</Label>
                    <Select id="select-add-skill" options={allSkills} onChange={(value) => handleAddSkill(value)} />
                </div>
            </div>
            <div className="my-4 flex space-x-4">
                <Button onClick={handlePrevious} type="button" colorScheme="whiteOutline" size="lg">
                    Previous
                </Button>
                <Button data_cy="button-skills" type="submit" colorScheme="default" size="lg">
                    Next
                </Button>
            </div>
        </div>
    );
};

export default SkillsForm;
