import React from 'react';
import dynamic from 'next/dynamic';
const ReactCodeInput = dynamic(import('react-code-input'));

const style = {
    inputStyle: {
        fontFamily: 'Helvetica',
        color: '#6b7280',
        fontSize: '24px',
        margin: '4px',
        MozAppearance: 'textfield',
        width: '40px',
        height: '40px',
        borderRadius: '3px',
        textAlign: 'center',
        backgroundColor: 'white',
        border: '1px solid #d1d5db',
        webkitAppearance: 'none',
    },
    inputStyleInvalid: {
        fontFamily: 'monospace',
        margin: '4px',
        MozAppearance: 'textfield',
        width: '15px',
        borderRadius: '3px',
        fontSize: '14px',
        height: '26px',

        backgroundColor: 'black',
        color: 'red',
        border: '1px solid red',
    },
};

const CodeInput = (props) => {
    const { onChange, value } = props;
    return (
        <ReactCodeInput type="text" fields={6} onChange={onChange} value={value} inputStyle={style.inputStyle} inputStyleInvalid={style.inputStyleInvalid} />
    );
};

export default CodeInput;
