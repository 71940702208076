import React, { useContext } from 'react';

import { Label, Select } from '../../Atoms/Form';
import { Button } from '../../Atoms/Elements';
import { useRouter } from 'next/router';

import { SearchContextV2 } from '../../../context/SearchContextV2';

const BasicSearchForm = () => {
    const router = useRouter();
    const { branches, branch, setBranch, jobs, job, setJob } = useContext(SearchContextV2);

    const search = (e) => {
        e.preventDefault();

        router.push({ pathname: '/s' });
    };

    return (
        <div className="grid grid-cols-1 gap-y-4 items-end px-8 py-10 bg-white shadow-sm rounded-lg sm:grid-cols-8 sm:gap-x-4 sm:px-20">
            <div className="sm:col-span-3">
                <Label>Branch</Label>
                <Select className="w-full" id="" name="branch" placeholder="Select a branch" options={branches} value={branch} onChange={setBranch} />
            </div>

            <div className="sm:col-span-3">
                <Label>Job</Label>
                <Select className="w-full" id="" name="job" placeholder="Select a job" options={jobs} value={job} onChange={setJob} />
            </div>
            <div className="sm:col-span-2">
                <Button data_cy="button-preferences" type="button" colorScheme="purple" size="lg" onClick={search}>
                    Search jobs
                </Button>
            </div>
        </div>
    );
};

export default BasicSearchForm;
