import { Controller } from 'react-hook-form';
import Select from 'react-select';
const SelectInput = (props) => (
    <div>
        <label htmlFor={props.name} className="block text-sm font-medium text-gray-700">
            {props.label}
        </label>
        <div className="mt-1">
            <Controller
                id={props.name}
                name={props.name}
                control={props.control}
                rules={{ required: true }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.name}
                options={props.options}
                defaultValue={props.defaultValue}
                value={props.value}
                as={Select}
                isSearchable={false}
            />
        </div>
    </div>
);

export default SelectInput;
