import React from 'react';
import { CheckIcon } from '@heroicons/react/solid';

const Pill = (props) => {
    const { selected, onClick, onRemove, data_cy } = props;
    const activeColor = 'bg-green-200 text-green-600';
    const defaultColor = 'bg-white text-gray-900 ';

    return (
        <span
            data-cy={data_cy}
            onClick={!selected ? onClick : onRemove}
            className={`text-sm ${selected ? activeColor : defaultColor} py-2 px-4 rounded-md cursor-pointer relative`}
        >
            {props.children}
            {selected && (
                <div className="absolute rounded-full px-1 py-1  bg-green-300" style={{ top: '-5px', right: '-5px' }}>
                    <CheckIcon className="h-2 w-2 text-white" />
                </div>
            )}
        </span>
    );
};

export default Pill;
