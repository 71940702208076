import mixpanel from 'mixpanel-browser';
import axios from 'axios';
mixpanel.init(process.env.MIXPANEL_KEY, { api_host: 'https://api-eu.mixpanel.com' }, '');

const IPList = ['81.207.0.5', '92.110.100.166', '192.168.178.223'];

let actions = {
    identify: async (id) => {
        const { data } = await axios.get('https://api.ipify.org/?format=json');
        if (!IPList.includes(data.ip)) {
            mixpanel.identify(id);
        }
    },
    alias: async (id) => {
        const { data } = await axios.get('https://api.ipify.org/?format=json');
        if (!IPList.includes(data.ip)) {
            mixpanel.alias(id);
        }
    },
    track: async (name, props) => {
        const { data } = await axios.get('https://api.ipify.org/?format=json');
        if (!IPList.includes(data.ip) || process.env.APP_ENV !== 'production') {
            mixpanel.track(name, props);
        }
    },
    people: {
        set: async (props) => {
            const { data } = await axios.get('https://api.ipify.org/?format=json');
            if (!IPList.includes(data.ip)) {
                mixpanel.people.set(props);
            }
        },
    },
};

export let Mixpanel = actions;
