import { useEffect, useState, useReducer } from 'react';
import axios from 'axios';
import searchFile from '../helpers/searchFile.json';

const useAuth = () => {
    const [branchList, setBranchList] = useState(null);
    const [jobList, setJobList] = useState(null);
    const [jobOptions, setJobOptions] = useState(null);
    const [workLevel, setWorkLevel] = useState(null);

    useEffect(() => {
        async function fetchSearchData() {
            //get branch and jobs
            const {
                data: {
                    data: { branches, jobtitles },
                },
            } = await axios.get(`${process.env.STUDIO_WEB_API}/jobtitles/both`);

            setBranchList(branches);
            setJobOptions(jobtitles);
            setJobList(jobtitles);
            setWorkLevel(searchFile.workLevel);
        }
        fetchSearchData();
    }, []);

    const filterConnectJobs = (value) => {
        const filterJobTitles = value.length !== 0 && jobList.filter((job) => job.branch === value._id);
        setJobOptions(filterJobTitles);
    };

    return { branchList, jobOptions, workLevel, filterConnectJobs };
};

export default useAuth;
