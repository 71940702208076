import React from 'react';
const TextArea = React.forwardRef((props, ref) => {
    const errorStyle = 'border-red-300 focus:ring-red-500 focus:border-red-500';
    const normalStyle = 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500';
    return (
        <div>
            <label htmlFor={props.name} className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                {props.label}
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <textarea
                    data-cy={props.data_cy}
                    id={props.name}
                    name={props.name}
                    rows="3"
                    className={`max-w-lg shadow-sm block w-full px-4 py-2 border ${props.errors ? errorStyle : normalStyle} rounded-md  sm:text-sm`}
                    ref={ref}
                    defaultValue={props.value}
                />
                {!props.errors && <p className="mt-2 text-sm text-gray-500 ">{props.detailText}</p>}
            </div>
        </div>
    );
});

export default TextArea;
