import React from 'react';

const RequiredFieldMessage = (props) => {
    const { children, data_cy } = props;
    return (
        <p className="mt-2 text-sm text-red-600" data-cy={data_cy}>
            {children}
        </p>
    );
};

export default RequiredFieldMessage;
