import React from 'react';
import { RequiredFieldMessage } from './';

const FileInput = React.forwardRef((props, ref) => {
    const errorStyle = 'border-red-300 focus:ring-red-500 focus:border-red-500';
    const normalStyle = 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500';
    return (
        <div className="">
            <label htmlFor={props.name} className="block text-sm font-medium text-gray-700">
                {props.label}
            </label>
            <div className="mt-1 sm:col-span-2">
                <div className="flex items-center">
                    <input
                        data-cy={props.data_cy}
                        id={props.name}
                        type="file"
                        className={`bg-white py-2 px-3 border rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 ${
                            props.errors ? errorStyle : normalStyle
                        } hover:bg-gray-50`}
                        ref={ref}
                        name={props.name}
                        accept={props.accept}
                    />
                </div>
            </div>
            {props.errors && <RequiredFieldMessage data_cy={props.data_cy}> This field is required</RequiredFieldMessage>}
        </div>
    );
});

export default FileInput;
