import { useEffect, useState, useReducer } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { initialState, reducer } from '../reducer/skills';

const useSkills = () => {
    const [skillsOptions, setSkillsOptions] = useState(null);
    const [recommendedSkills, setRecommendedSkills] = useState(null);
    const [skillsData, setDataSkills] = useState(null);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [skillsState, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        async function fetchSkills() {
            try {
                const { data } = await axios.get(`${process.env.STUDIO_WEB_API}/skills`);
                setDataSkills(data);
                dispatch({ type: 'set_skills', payload: data });
            } catch (err) {
                dispatch({ type: 'set_skills', payload: [] });
                setDataSkills(null);
            }
        }

        fetchSkills();
    }, []);

    const getRecommendedSkills = (jobId, userSkills) => {
        const skills_per_job = skillsData.filter((x) => x.jobIds.includes(jobId));
        //If recommended jobs are empty notify user
        if (skills_per_job.length === 0) {
            return toast("We couldn't find any recommend skills 😞");
        }
        var merged_skills = skills_per_job.filter((x) => !userSkills.some((item) => item._id === x._id));

        setRecommendedSkills(merged_skills);
    };

    const removeRecommendedSkill = (skillId) => {
        setRecommendedSkills(recommendedSkills.filter((item) => item._id !== skillId));
    };

    const selectSkill = (v) => {
        if (selectedSkills.length === 0) {
            setSelectedSkills((prevItems) => [...prevItems, v]);
        }
        if (selectedSkills.length !== 0) {
            setSelectedSkills((prevItems) => [...prevItems, v]);
        }
    };

    const getSkillsPerJob = (jobId, userSkills) => {
        // set options empty if job pref is not filled in
        if (userSkills.length !== 0) {
            setSkillsOptions(userSkills);
        }
    };

    const addSkill = (v) => {
        setSkillsOptions([...skillsOptions, v]);
    };
    const removeSkill = (v) => {
        const skills = selectedSkills.filter((x) => x.value !== v.value);
        setSelectedSkills(skills);
    };

    const addToAllSkills = (skill) => {
        setDataSkills([...skillsData, skill]);
    };

    const removeFromAllSkills = (id) => {
        setDataSkills(skillsData.filter(({ _id }) => _id !== id));
    };

    return {
        getSkillsPerJob,
        selectSkill,
        selectedSkills,
        skillsOptions,
        addSkill,
        skillsData,
        getRecommendedSkills,
        removeSkill,
        skillsState,
        recommendedSkills,
        removeRecommendedSkill,
        removeFromAllSkills,
        addToAllSkills,
    };
};

export default useSkills;
