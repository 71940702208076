import { useEffect, useState, useReducer, useContext } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import { initialAuthState, reducer } from '../reducer/auth';
import { AuthContext } from '../context/AuthContext';
import { Mixpanel } from '../helpers/mixpanel';

const useAuth = () => {
    const { settingUser } = useContext(AuthContext);
    const [authState, dispatch] = useReducer(reducer, initialAuthState);
    const [currentStep, setCurrentStep] = useState(0);
    const [isFinished, setIsFinished] = useState(false);

    const handleRegisterRequest = async (values) => {
        dispatch({ type: 'loading' });
        try {
            const response = await axios.post(`${process.env.PEOPLE_WEB_API}/users/registration`, values);
            const { data } = response.data;
            Mixpanel.identify(data._id);
            Mixpanel.track('Signup');
            Mixpanel.people.set({
                $first_name: data.firstName,
                $last_name: data.lastName,
                $email: data.email,
            });
            dispatch({ type: 'create_user_account', payload: response });
            return setCurrentStep(2);
        } catch (err) {
            return dispatch({ type: 'error', payload: err });
        }
    };

    const handleLoginRequest = async (values) => {
        dispatch({ type: 'loading' });
        try {
            const response = await axios.post(`${process.env.PEOPLE_WEB_API}/users/authentication`, values);

            const { user, token } = response.data;
            settingUser(user);
            Cookies.set('otellu-people-jwt-token', token);
        
            setIsFinished(true);

            Mixpanel.identify(user._id);
            Mixpanel.track('Login');
            Mixpanel.people.set({
                $first_name: user.firstName,
                $last_name: user.lastName,
                $email: user.email,
            });

            return dispatch({ type: 'authenticate', payload: response });
        } catch (err) {
            return dispatch({ type: 'error', payload: err });
        }
    };

    const handleVerifyRequest = async (values) => {
        dispatch({ type: 'loading' });
        try {
            const response = await axios.post(`${process.env.PEOPLE_WEB_API}/users/registration/verify`, { ...values });
            dispatch({ type: 'verify_account', payload: response });
            const { user, token } = response.data.data;

            //combine onboarding values and user data from db
            if (authState.skills && authState.preferences) {
                const updated_user = { ...user, skills: authState.skills, preferences: authState.preferences };
                settingUser(updated_user);
            }

            if (!authState.skills && !authState.preferences) {
                settingUser(user);
            }

            Cookies.set('otellu-people-jwt-token', token);

            Mixpanel.identify(user._id);
            Mixpanel.track('verify');
            Mixpanel.people.set({
                $first_name: user.firstName,
                $last_name: user.lastName,
                $email: user.email,
            });

            setCurrentStep(0);
            setIsFinished(true);
            return response;
        } catch (err) {
            return dispatch({ type: 'error', payload: err });
        }
    };

    const handleResendVerifyRequest = async ({ email, toastNotify }) => {
        dispatch({ type: 'loading' });
        try {
            const response = await axios.post(`${process.env.PEOPLE_WEB_API}/users/resend-verify`, { email: email });
            const { user } = response.data.data;
            if (toastNotify) {
                toast.success(response.data.message);
            }
            dispatch({ type: 'resend_verify', payload: user });
            return response;
        } catch (err) {
            return dispatch({ type: 'error', payload: err });
        }
    };

    const updateUser = async (value) => {
        dispatch({ type: 'loading' });
        try {
            const response = await axios.put(`${process.env.PEOPLE_WEB_API}/users`, value);
            const { data } = response.data;
            settingUser(data);
            dispatch({ type: 'update_user', payload: data });
            return response;
        } catch (err) {
            return dispatch({ type: 'error', payload: err });
        }
    };

    const handleJobPrefs = async (prefs, shouldUpdateUser) => {
        setCurrentStep(currentStep + 1);
        Mixpanel.track('onboard - job preferences');
        if (shouldUpdateUser) {
            updateUser({ ...authState.user, preferences: prefs, jobMailEnabled: true });
        }
        return dispatch({ type: 'add_preferences', payload: prefs });
    };

    const handleSkills = async (selectedSkills, shouldUpdateUser) => {
        setCurrentStep(currentStep + 1);
        Mixpanel.track('onboard - skills');

        if (shouldUpdateUser) {
            updateUser({ ...authState.user, skills: selectedSkills });
        }
        return dispatch({ type: 'add_skills', payload: selectedSkills });
    };

    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };

    const handlePrevious = () => {
        setCurrentStep(currentStep - 1);
    };

    return {
        currentStep,
        isFinished,
        setCurrentStep,
        handleRegisterRequest,
        handleLoginRequest,
        handleVerifyRequest,
        handleResendVerifyRequest,
        handleNext,
        handlePrevious,
        authState,
        handleSkills,
        handleJobPrefs,
        updateUser,
    };
};

export default useAuth;
