import React from 'react';
import Link from 'next/link';
import { Label, Input, CodeInput, Select, Pill } from '../../Atoms/Form';
import { Button } from '../../Atoms/Elements';

const LoginForm = (props) => {
    const { onChange, handleRedirect, loading, hideRedirect } = props;
    return (
        <div>
            <div className="space-y-4">
                <div>
                    <Label name="username" extraLabelAction={() => alert('extra label action')}>
                        Email
                    </Label>
                    <Input data_cy="input-email" onChange={onChange} type="email" placeholder="elon.musk@gmail.com" name="username" required={true} />
                </div>
                <div>
                    <Label name="password" extraLabelAction={() => alert('extra label action')}>
                        Password
                    </Label>
                    <Input data_cy="input-password" onChange={onChange} type="password" placeholder="**************" name="password" required={true} />
                </div>
            </div>
            <Link href="/forgot-password">
                <p className="text-sm text-right mt-1 underline text-gray-500 cursor-pointer">Forgot password?</p>
            </Link>
            <div className="my-4">
                <Button data_cy="button-login" type="submit" colorScheme="default" size="lg" loading={loading}>
                    Sign in
                </Button>
            </div>
            {!hideRedirect && (
                <p onClick={handleRedirect} data-cy="link-to-register" className="text-sm text-gray-500 underline text-center cursor-pointer">
                    Do not have an account? Sign up
                </p>
            )}
        </div>
    );
};

export default LoginForm;
