export const initialAuthState = {
    loading: false,
    fetching: false,
    error: false,
    message: false,
    success: false,
    user: null,
    skills: null,
    preferences: null,
};

export const reducer = (state, action) => {
    if (action.type === 'loading') {
        return { ...state, loading: true, erorr: false, success: false, message: '' };
    }

    if (action.type === 'error') {
        const { message } = action.payload.response.data;
        return { ...state, loading: false, success: false, error: true, message: message };
    }

    if (action.type === 'authenticate') {
        const { user } = action.payload.data;
        return { ...state, user: user, loading: false };
    }

    if (action.type === 'create_user_account') {
        const { data } = action.payload.data;
        return { ...state, user: data, loading: false };
    }

    if (action.type === 'verify_account') {
        return { ...state, loading: false };
    }

    if (action.type === 'resend_verify') {
        const { payload } = action;
        return { ...state, loading: false, user: payload };
    }

    if (action.type === 'add_skills') {
        const { payload } = action;
        return { ...state, skills: payload };
    }

    if (action.type === 'add_preferences') {
        const { payload } = action;
        return { ...state, preferences: payload };
    }

    if (action.type === 'update_user') {
        const { payload } = action;
        return { ...state, user: payload, loading: false };
    }
};
