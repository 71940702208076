import { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import searchFile from '../helpers/searchFile';
import { findSearchField } from '../helpers/search';

const UIContext = createContext();

const UIProvider = ({ children }) => {
    const [mobileSearchState, setMobileSearchState] = useState(false);
    const [mobileMenuState, setMobileMenuState] = useState(false);
    const [mobileStudioMenuState, setMobileStudioMenuState] = useState(false);

    const toggleMobileSearch = () => {
        setMobileSearchState(!mobileSearchState);
    };
    const toggleMobileMenu = (state) => {
        if (state === undefined) {
            setMobileMenuState(!mobileMenuState);
        } else {
            setMobileMenuState(state);
        }
    };

    const toggleStudioMenu = (state) => {
        if (state === undefined) {
            setMobileStudioMenuState(!mobileStudioMenuState);
        } else {
            setMobileStudioMenuState(state);
        }
    };

    return (
        <UIContext.Provider
            value={{
                toggleMobileSearch,
                mobileSearchState,
                toggleMobileMenu,
                toggleStudioMenu,
                mobileMenuState,
                mobileStudioMenuState,
            }}
        >
            {children}
        </UIContext.Provider>
    );
};

export { UIProvider, UIContext };
