const Step = (props) => {
    return (
        <li className={`relative md:flex-1 md:flex`}>
            <a href="#" className="px-6 py-4 flex items-center text-sm font-medium">
                {props.status === 'open' && (
                    <>
                        <span
                            className={`flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-indigo-600 rounded-full ${
                                props.currentApplyIdx === props.stepIdx && 'bg-indigo-600'
                            }`}
                            aria-current="step"
                        >
                            <span className={`${props.currentApplyIdx === props.stepIdx ? 'text-white' : 'text-indigo-600'}`}>{props.number}</span>
                        </span>
                        <span className="ml-4 text-sm font-medium text-indigo-600">{props.title}</span>
                    </>
                )}
            </a>
            {props.last ? null : (
                <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                    <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                        <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round" />
                    </svg>
                </div>
            )}
        </li>
    );
};

export default Step;
