export const initialState = {
    loading: false,
    fetching: false,
    error: false,
    message: false,
    success: false,
    recommendedSkills: [],
    selectedSkills: [],
    allSkills: [],
};

export const reducer = (state, action) => {
    if (action.type === 'loading') {
        return { ...state, loading: true, erorr: false, success: false, message: '' };
    }

    if (action.type === 'set_skills') {
        const { payload } = action;
        return { ...state, allSkills: payload };
    }

    if (action.type === 'add_recommended_skills') {
        const { skillsData, jobId } = action.payload;
        const skills_per_job = skillsData.filter((x) => x.jobIds.includes(jobId));
        return { ...state, recommendedSkills: skills_per_job };
    }

    if (action.type === 'select_skill') {
        const { payload } = action;
        return {
            ...state,
            selectedSkills: [...state.selectedSkills, payload],
        };
    }

    if (action.type === 'add_skill') {
        const { payload } = action;
        return {
            ...state,
            recommendedSkills: [...state.recommendedSkills, payload],
            selectedSkills: [...state.selectedSkills, payload],
        };
    }

    if (action.type === 'remove_skill') {
        const { payload } = action;
        return {
            ...state,
            selectedSkills: state.selectedSkills.filter((skill) => skill._id !== payload._id),
        };
    }
};
