import { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { AuthContext } from '../context/AuthContext';
import { Mixpanel } from '../helpers/mixpanel';
import { uo_url, wo_url } from '../helpers/url';
import { Modal } from '../components/Atoms/Modal';
import { AuthForms } from '../components/Molecules/Auth';

const JobContext = createContext();

const JobProvider = ({ children }) => {
    const { settingUser, user } = useContext(AuthContext)
    const [latestJobs, setLatestsJob] = useState(null)
    const [saveModalOpen, setSaveModalOpen] = useState(false)
    const [jobToSave, setJobToSave] = useState(null)

    const [jobReqState, setJobReqState] = useState({
        loading: false,
        error: false,
        message: '',
        fetching: false,
    });

    const saveJob = async (userId, jobId) => {
        setJobReqState({
            loading: true,
            error: false,
            message: '',
        });

        const uId = userId || user._id

        try {
            const response = await axios.post(`${uo_url}/users/save-job`, {
                userId: uId,
                jobId,
            });
            //set job in user
            settingUser(response.data.data);

            //toast
            toast.success(response.data.message);

            //mixpanel
            Mixpanel.track('save job', { vacancyId: jobId });

            setJobToSave(null)

            return setJobReqState({
                loading: false,
                error: false,
            });
        } catch (err) {
            //toast
            return setJobReqState({
                loading: false,
                error: false,
            });
        }
    };

    const getLatestJobs = async (prefs) => {
        setJobReqState({
            loading: false,
            err: false,
            message: '',
            fetching: true,
        });
        try {
            const res = await axios.get(
                `${uo_url}/search/external?branch=${prefs.branch.enum}&job=${camelize(prefs.job.enum)}&workLevel=${prefs.workLevel.name}`
            );
            const { data } = res;
            setLatestsJob(data);
            return setJobReqState({
                loading: false,
                err: false,
                message: '',
                fetching: false,
            });
        } catch (err) {
            setLatestsJob(null);
            setJobReqState({
                loading: false,
                err: true,
                message: '',
                fetching: false,
            });
            return err;
        }
    };

    function camelize(str) {
        return str
            .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
                return index === 0 ? word.toLowerCase() : word.toUpperCase();
            })
            .replace(/\s+/g, '');
    }
    return (
        <JobContext.Provider value={{
            saveJob,
            getLatestJobs,
            latestJobs,
            jobReqState,
            saveModalOpen,
            setSaveModalOpen,
            setJobToSave,
        }}>
            <Modal
                onClose={() => setSaveModalOpen(false)}
                isOpen={saveModalOpen}
                modalWidth="max-w-lg"
            >
                <AuthForms onFinish={() => { setSaveModalOpen(false); saveJob(null, jobToSave) }} />
            </Modal>

            {children}
        </JobContext.Provider>
    );
};

export { JobProvider, JobContext };
