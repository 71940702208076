import React from 'react';

const ModalHeader = (props) => {
    const { title, description, data_cy } = props;
    return (
        <div data-cy={data_cy} className="text-center py-4">
            <h2 className="text-xl sm:text-2xl font-bold text-gray-900">{title}</h2>
            <div className="max-w-lg mx-auto mt-1">
                <p className="text-gray-500 text-sm">{description}</p>
            </div>
        </div>
    );
};

export default ModalHeader;
