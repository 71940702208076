import React from 'react';
import { Label, Input, CodeInput, Select, Pill } from '../../Atoms/Form';
import { Button } from '../../Atoms/Elements';
import { useRouter } from 'next/router';

const RegisterForm = (props) => {
    const { loading, values, onChange, handleRedirect, hideRedirect } = props;
    const router = useRouter();
    return (
        <>
            <div className="space-y-4">
                <div>
                    <Label name="firstName" extraLabelAction={() => alert('extra label action')}>
                        First name
                    </Label>
                    <Input data_cy="input-firstName" onChange={onChange} type="text" placeholder="Elon" name="firstName" required={true} />
                </div>
                <div>
                    <Label name="lastName" extraLabelAction={() => alert('extra label action')}>
                        Lastname
                    </Label>
                    <Input data_cy="input-lastName" onChange={onChange} type="text" placeholder="Musk" name="lastName" required={true} />
                </div>
                <div>
                    <Label name="email" extraLabelAction={() => alert('extra label action')}>
                        Email
                    </Label>
                    <Input data_cy="input-email" onChange={onChange} type="email" placeholder="elon.musk@gmail.com" name="email" required={true} />
                </div>
                <div>
                    <Label name="password" extraLabelAction={() => alert('extra label action')}>
                        Password
                    </Label>
                    <Input
                        data_cy="input-password"
                        onChange={onChange}
                        type="password"
                        placeholder="**************"
                        name="password"
                        value={values['password']}
                        required={true}
                    />
                </div>
            </div>
            <div className="my-4">
                <Button data_cy="button-register" type="submit" colorScheme="default" size="lg" loading={loading}>
                    Create account
                </Button>
            </div>
            {!hideRedirect && (
                <p onClick={handleRedirect} data-cy="link-to-login" className="text-sm text-gray-500 underline text-center cursor-pointer">
                    Already have an account? Login
                </p>
            )}
            {hideRedirect && (
                <p onClick={() => router.push('/login')} data-cy="link-to-login" className="text-sm text-gray-500 underline text-center cursor-pointer">
                    Already have an account? Login
                </p>
            )}
        </>
    );
};

export default RegisterForm;
