import React, { useContext } from 'react';
import Link from 'next/link';
import Select from 'react-select';
import { FileInput, PhoneInput, SelectInput, TextArea, TextInput } from '../../Atoms/Form';
import Button from '../../Buttons';
import { AuthContext } from '../../../context/AuthContext';
import { Alert } from '../../Atoms/Notications';

const ResetPassword = (props) => {
    const { handleSubmit, handleSubmitForm, register } = props;
    const { loading, message, error } = useContext(AuthContext);

    return (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
            <TextInput type="password" label="New password" size="big" placeholder="********" name="password" ref={register({ required: true })} />
            <div className="mt-4">
                <div>
                    <Button loading={loading} type="submit" text="Submit" theme="btn btn-purple" />
                </div>
            </div>
            <div className="mx-auto mt-4">{!loading && message && <Alert message={message} status={error ? 'error' : 'success'} />}</div>
        </form>
    );
};

export default ResetPassword;
