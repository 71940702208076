import React from 'react';
import Cookie from 'js-cookie';
import jwt from 'jsonwebtoken';
import Router from 'next/router';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import NProgress from 'nprogress';
import { Toaster } from 'react-hot-toast';

import { Maintenance } from '../components/Molecules/States';

import { SearchProvider } from '../context/SearchContext';
import { SearchProviderV2 } from '../context/SearchContextV2';

import { AuthProvider } from '../context/AuthContext';
import { UIProvider } from '../context/UIContext';
import { JobProvider } from '../context/JobContext';
import { Mixpanel } from '../helpers/mixpanel';

import 'nprogress/nprogress.css';
import '../styles/main.css';
import '../styles/range-slider.css';
import '../styles/prism.css';
import '../styles/external-jobs.css';

const queryClient = new QueryClient();

NProgress.configure({
    minimum: 0.3,
    easing: 'ease',
    speed: 800,
    showSpinner: false,
});

Router.events.on('routeChangeStart', (url) => {
    NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const visitor_cookie = Cookie.get('otellu_visitor_token');

if (visitor_cookie) {
    jwt.verify(visitor_cookie, process.env.JWT_SECRET, function (err, decoded) {
        if (err) {
            const visitor_token = jwt.sign({ foo: 'bar' }, process.env.JWT_SECRET, { expiresIn: 2 * 60 * 60 });
            Cookie.set('otellu_visitor_token', visitor_token);
            Mixpanel.track('Visit otellu');
        }
    });
}

if (!visitor_cookie) {
    const visitor_token = jwt.sign({ foo: 'bar' }, 'shhhhh', { expiresIn: 2 * 60 * 60 });
    Cookie.set('otellu_visitor_token', visitor_token);
    Mixpanel.track('Visit otellu');
}

const MyApp = ({ Component, pageProps }) => {
    if (process.env.MAINTENANCE_MODE) {
        return <Maintenance />;
    }
    return (
        <AuthProvider>
            <UIProvider>
                <SearchProvider>
                    <SearchProviderV2>
                        <JobProvider>
                            <QueryClientProvider client={queryClient}>
                                <Component {...pageProps} />
                                <Toaster position="bottom-center" />
                            </QueryClientProvider>
                        </JobProvider>
                    </SearchProviderV2>
                </SearchProvider>
            </UIProvider>
        </AuthProvider>
    );
};

export default MyApp;
