import React from 'react';

const PhoneInput = React.forwardRef((props, ref) => {
    const errorStyle = 'border-red-300 focus:ring-red-500 focus:border-red-500 active:border-red-500';
    const normalStyle = 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 active:border-indigo-500';

    return (
        <div>
            <label htmlFor={props.name} className="block text-sm font-medium text-gray-700">
                {props.label}
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                            />
                        </svg>
                    </svg>
                </div>
                <input
                    data-cy={props.data_cy}
                    id={props.name}
                    type="tel"
                    name={props.name}
                    className={`block w-full px-2 py-2 pl-10 sm:text-sm border ${props.errors ? errorStyle : normalStyle} rounded-md`}
                    placeholder={props.placeholder}
                    ref={ref}
                    defaultValue={props.value}
                />
            </div>
        </div>
    );
});

export default PhoneInput;
