import React from 'react';
import { Label, Select } from '../../Atoms/Form';
import { Button } from '../../Atoms/Elements';

import useSearch from '../../../hooks/useSearch';

const JobPreferenceForm = (props) => {
    const { branchList, jobOptions, workLevel, filterConnectJobs } = useSearch();
    const { onChange, onBlur, values, errors, touched } = props;

    return (
        <div>
            <div className="space-y-4">
                <div>
                    <Label>Branch</Label>
                    <Select
                        id="select-apply-branch"
                        isError={touched.branch && errors.branch}
                        name="branch"
                        placeholder="Select a branch"
                        options={branchList}
                        value={values.branch}
                        onChange={(e) => {
                            onChange(e, 'branch');
                            filterConnectJobs(e);
                        }}
                        onBlur={() => onBlur('branch')}
                    />
                    {touched.branch && errors.branch && <p className="text-xs mt-1 text-red-400">{errors.branch}</p>}
                </div>
                <div>
                    <Label>Job</Label>
                    <Select
                        id="select-apply-job"
                        isError={touched.job && errors.job}
                        name="job"
                        value={values.job}
                        placeholder="Select a job"
                        options={jobOptions}
                        onChange={(e) => onChange(e, 'job')}
                        onBlur={() => onBlur('job')}
                    />
                    {touched.job && errors.job && <p className="text-xs mt-1 text-red-400">{errors.job}</p>}
                </div>
                <div>
                    <Label>Work level</Label>
                    <Select
                        id="select-apply-workLevel"
                        isError={touched.workLevel && errors.workLevel}
                        name="workLevel"
                        value={values.workLevel}
                        placeholder="Select your work level"
                        options={workLevel}
                        onChange={(e) => onChange(e, 'workLevel')}
                        onBlur={() => onBlur('workLevel')}
                    />
                    {touched.workLevel && errors.workLevel && <p className="text-xs mt-1 text-red-400">{errors.workLevel}</p>}
                </div>
            </div>
            <div className="my-4">
                <Button data_cy="button-preferences" type="submit" colorScheme="default" size="lg">
                    Next
                </Button>
            </div>
        </div>
    );
};

export default JobPreferenceForm;
