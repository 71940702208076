import axios from 'axios';
import searchFile from './searchFile';

export const findSearchField = async (obj) => {
    const fields = await Promise.all(
        Object.keys(obj)
            .map(async (key, index) => {
                if (obj[key] !== '') {
                    const list = await searchFile[key].find((el) => el.name === obj[key]);
                    return {
                        type: key,
                        list: list,
                    };
                }
            })
            .filter((x) => x)
    );
    const filter_field = fields.filter((x) => x.list != null);
    return filter_field;
};

export const searchQueries = [
    //Branches

    //Business
    {
        query: 'branch=Business/Finance&city=Amsterdam',
        hideInFooter: true,
        queryProps: {
            branch: {
                id: 1,
                name: 'Business/Finance',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Business jobs in Amsterdam',
        alternatives: ['business-jobs-amsterdam'],
    },
    //Finance
    {
        query: 'branch=Business/Finance&city=Amsterdam',
        queryProps: {
            branch: {
                id: 1,
                name: 'Business/Finance',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Finance jobs in Amsterdam',
        alternatives: ['finance-jobs-amsterdam', 'finance-jobs-amsterdam'],
    },
    //Accounting
    {
        query: 'branch=Accounting&city=Amsterdam',
        queryProps: {
            branch: {
                id: 2,
                name: 'Accounting',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Accounting jobs in Amsterdam',
        alternatives: ['accounting-jobs-amsterdam'],
    },
    //Legal
    {
        query: 'branch=Legal&city=Amsterdam',
        queryProps: {
            branch: {
                id: 3,
                name: 'Legal',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Legal jobs in Amsterdam',
        alternatives: ['legal-jobs-amsterdam'],
    },
    //Human Resource
    {
        query: 'branch=Human Resource&city=Amsterdam',
        queryProps: {
            branch: {
                id: 4,
                name: 'Human Resource',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Human Resource jobs in Amsterdam',
        alternatives: ['human-resource-jobs-amsterdam'],
    },
    //Sales
    {
        query: 'branch=Sales&city=Amsterdam',
        queryProps: {
            branch: {
                id: 5,
                name: 'Sales',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Sales jobs in Amsterdam',
        alternatives: ['sales-jobs-amsterdam', 'sales-amsterdam'],
    },

    //Tech
    {
        query: 'branch=Tech&city=Amsterdam',
        queryProps: {
            branch: {
                id: 6,
                name: 'Tech',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Tech jobs in Amsterdam',
        alternatives: ['tech-jobs-amsterdam'],
    },

    //Marketing
    {
        query: 'branch=Marketing&city=Amsterdam',
        queryProps: {
            branch: {
                id: 7,
                name: 'Marketing',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Marketing jobs in Amsterdam',
        alternatives: ['marketing-jobs-amsterdam'],
    },
    //Support
    {
        query: 'branch=Support&city=Amsterdam',
        queryProps: {
            branch: {
                id: 8,
                name: 'Support',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Support jobs in Amsterdam',
        alternatives: ['support-jobs-amsterdam'],
    },

    //Design
    {
        query: 'branch=Design&city=Amsterdam',
        queryProps: {
            branch: {
                id: 9,
                name: 'Design',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Design jobs in Amsterdam',
        alternatives: ['design-jobs-amsterdam'],
    },

    //-------------------------------------------------------------------
    //************* JOB TITLES *****************

    //Data analyst
    {
        query: 'branch=Business/Finance&job=dataAnalyst&city=Amsterdam',
        queryProps: {
            branch: {
                id: 5,
                name: 'Business/Finance',
            },
            job: {
                name: 'Data Analyst',
                branchId: 1,
                jobId: 1,
                enum: 'dataAnalyst',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Data analyst jobs in Amsterdam',
        alternatives: ['data-analyst-jobs-amsterdam'],
    },

    //Product Owner
    {
        query: 'branch=Business/Finance&job=productOwner&city=Amsterdam',
        queryProps: {
            branch: {
                id: 1,
                name: 'Business/Finance',
            },
            job: {
                name: 'Product Owner',
                branchId: 1,
                jobId: 1,
                enum: 'productOwner',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Product Owner jobs in Amsterdam',
        alternatives: ['product-owner-jobs-amsterdam'],
    },

    //Project manager
    {
        query: 'branch=Business/Finance&job=projectManager&city=Amsterdam',
        queryProps: {
            branch: {
                id: 1,
                name: 'Business/Finance',
            },
            job: {
                name: 'Project manager',
                branchId: 1,
                jobId: 3,
                enum: 'projectManager',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Project manager jobs in Amsterdam',
        alternatives: ['project-manager-jobs-amsterdam'],
    },

    //Business analyst
    {
        query: 'branch=Business/Finance&job=businessAnalyst&city=Amsterdam',
        queryProps: {
            branch: {
                id: 1,
                name: 'Business/Finance',
            },
            job: {
                name: 'Business analyst',
                branchId: 1,
                jobId: 4,
                enum: 'businessAnalyst',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Business analyst jobs in Amsterdam',
        alternatives: ['business-analyst-jobs-amsterdam'],
    },

    //Data scientist
    {
        query: 'branch=Business/Finance&job=dataScientist&city=Amsterdam',
        queryProps: {
            branch: {
                id: 1,
                name: 'Business/Finance',
            },
            job: {
                name: 'Data scientist',
                branchId: 1,
                jobId: 6,
                enum: 'dataScientist',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Data scientist jobs in Amsterdam',
        alternatives: ['data-scientist-jobs-amsterdam'],
    },

    //Financial controller
    {
        query: 'branch=Accounting&job=financialController&city=Amsterdam',
        queryProps: {
            branch: {
                id: 2,
                name: 'Accounting',
            },
            job: {
                name: 'Financial Controller',
                branchId: 2,
                jobId: 7,
                enum: 'financialController',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Financial controller jobs in Amsterdam',
        alternatives: ['financial-controller-jobs-amsterdam'],
    },

    //Accountant
    {
        query: 'branch=Accounting&job=accountant&city=Amsterdam',
        queryProps: {
            branch: {
                id: 2,
                name: 'Accounting',
            },
            job: {
                name: 'Accountant',
                branchId: 2,
                jobId: 7,
                enum: 'accountant',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Accountant jobs in Amsterdam',
        alternatives: ['accountant-jobs-amsterdam'],
    },

    //Legal counsel
    {
        query: 'branch=Legal&job=legalCounsel&city=Amsterdam',
        queryProps: {
            branch: {
                id: 3,
                name: 'Legal',
            },
            job: {
                name: 'Legal counsel',
                branchId: 3,
                jobId: 8,
                enum: 'legalCounsel',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Legal counsel jobs in Amsterdam',
        alternatives: ['legal-counsel-jobs-amsterdam'],
    },

    //Compliance Officer
    {
        query: 'branch=Legal&job=complianceOfficer&city=Amsterdam',
        queryProps: {
            branch: {
                id: 3,
                name: 'Legal',
            },
            job: {
                name: 'Compliance Officer',
                branchId: 3,
                jobId: 9,
                enum: 'complianceOfficer',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Compliance Officer jobs in Amsterdam',
        alternatives: ['compliance-officer-jobs-amsterdam'],
    },

    //Recruiter
    {
        query: 'branch=Human Resource&job=recruiter&city=Amsterdam',
        queryProps: {
            branch: {
                id: 4,
                name: 'Human Resource',
            },
            job: {
                name: 'Recruiter',
                branchId: 4,
                jobId: 11,
                enum: 'recruiter',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Recruiter jobs in Amsterdam',
        alternatives: ['recruiter-jobs-amsterdam'],
    },

    //HR Manager
    {
        query: 'branch=Human Resource&job=hRManager&city=Amsterdam',
        queryProps: {
            branch: {
                id: 4,
                name: 'Human Resource',
            },
            job: {
                name: 'HR Manager',
                branchId: 4,
                jobId: 12,
                enum: 'hRManager',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'HR Manager jobs in Amsterdam',
        alternatives: ['hr-manager-jobs-amsterdam'],
    },

    //Sales Manager
    {
        query: 'branch=Sales&job=salesManager&city=Amsterdam',
        queryProps: {
            branch: {
                id: 5,
                name: 'Sales',
            },
            job: {
                name: 'Sales Manager',
                branchId: 5,
                jobId: 13,
                enum: 'salesManager',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Sales Manager jobs in Amsterdam',
        alternatives: ['sales-manager-jobs-amsterdam'],
    },

    //Account Manager
    {
        query: 'branch=Sales&job=accountManager&city=Amsterdam',
        queryProps: {
            branch: {
                id: 5,
                name: 'Sales',
            },
            job: {
                name: 'Account Manager',
                branchId: 5,
                jobId: 14,
                enum: 'accountManager',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Account Manager jobs in Amsterdam',
        alternatives: ['account-manager-jobs-amsterdam'],
    },

    //Technical Support Manager
    {
        query: 'branch=Sales&job=Frontend Developer&city=Amsterdam',
        queryProps: {
            branch: {
                id: 5,
                name: 'Sales',
            },
            job: {
                name: 'Technical Support Manager',
                branchId: 5,
                jobId: 15,
                enum: 'technicalSupportManager',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Technical Support Manager jobs in Amsterdam',
        alternatives: ['technical-support-manager-jobs-amsterdam'],
    },

    //Frontend Developer
    {
        query: 'branch=Tech&job=frontEndDeveloper&city=Amsterdam',
        queryProps: {
            branch: {
                id: 6,
                name: 'Tech',
            },
            job: {
                name: 'Frontend Developer',
                branchId: 6,
                jobId: 16,
                enum: 'frontEndDeveloper',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Frontend Developer jobs in Amsterdam',
        alternatives: ['frontend-developer-jobs-amsterdam'],
    },

    //Backend Developer
    {
        query: 'branch=Tech&job=backEndDeveloper&city=Amsterdam',
        queryProps: {
            branch: {
                id: 6,
                name: 'Tech',
            },
            job: {
                name: 'Backend Developer',
                branchId: 6,
                jobId: 17,
                enum: 'backEndDeveloper',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Backend Developer jobs in Amsterdam',
        alternatives: ['backend-developer-jobs-amsterdam'],
    },

    //Full Stack Developer
    {
        query: 'branch=Tech&job=fullStackDeveloper&city=Amsterdam',
        queryProps: {
            branch: {
                id: 6,
                name: 'Tech',
            },
            job: {
                name: 'Full Stack Developer',
                branchId: 6,
                jobId: 18,
                enum: 'fullStackDeveloper',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Full Stack Developer jobs in Amsterdam',
        alternatives: ['fullstack-developer-jobs-amsterdam'],
    },

    //Security Expert
    {
        query: 'branch=Tech&job=securityExpert&city=Amsterdam',
        queryProps: {
            branch: {
                id: 6,
                name: 'Tech',
            },
            job: {
                name: 'Security Expert',
                branchId: 6,
                jobId: 19,
                enum: 'securityExpert',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Security Expert jobs in Amsterdam',
        alternatives: ['sucurity-expert-jobs-amsterdam'],
    },

    //Security Expert
    {
        query: 'branch=Tech&job=securityExpert&city=Amsterdam',
        queryProps: {
            branch: {
                id: 6,
                name: 'Tech',
            },
            job: {
                name: 'Security Expert',
                branchId: 6,
                jobId: 19,
                enum: 'securityExpert',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Security Expert jobs in Amsterdam',
        alternatives: ['security-expert-jobs-amsterdam'],
    },

    //Cloud Engineer
    {
        query: 'branch=Tech&job=cloudEngineer&city=Amsterdam',
        queryProps: {
            branch: {
                id: 6,
                name: 'Tech',
            },
            job: {
                name: 'Cloud Engineer',
                branchId: 6,
                jobId: 20,
                enum: 'cloudEngineer',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Cloud Engineer jobs in Amsterdam',
        alternatives: ['cloud-engineer-jobs-amsterdam'],
    },

    //DevOps Engineer
    {
        query: 'branch=Tech&job=devOpsEngineer&city=Amsterdam',
        queryProps: {
            branch: {
                id: 6,
                name: 'Tech',
            },
            job: {
                name: 'DevOps Engineer',
                branchId: 6,
                jobId: 21,
                enum: 'devOpsEngineer',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'DevOps Engineer jobs in Amsterdam',
        alternatives: ['devops-engineer-jobs-amsterdam'],
    },
    //Android developer
    {
        query: 'branch=Tech&job=androidDeveloper&city=Amsterdam',
        queryProps: {
            branch: {
                id: 6,
                name: 'Tech',
            },
            job: {
                name: 'Android developer',
                branchId: 6,
                jobId: 22,
                enum: 'androidDeveloper',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Android developer jobs in Amsterdam',
        alternatives: ['android-developer-jobs-amsterdam'],
    },

    //IOS developer
    {
        query: 'branch=Tech&job=iosDeveloper&city=Amsterdam',
        queryProps: {
            branch: {
                id: 6,
                name: 'Tech',
            },
            job: {
                name: 'IOS developer',
                branchId: 6,
                jobId: 23,
                enum: 'iosDeveloper',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'IOS developer jobs in Amsterdam',
        alternatives: ['ios-developer-jobs-amsterdam'],
    },

    //Content Specialist
    {
        query: 'branch=Marketing&job=contentSpecialist&city=Amsterdam',
        queryProps: {
            branch: {
                id: 7,
                name: 'Marketing',
            },
            job: {
                name: 'Content Specialist',
                branchId: 7,
                jobId: 24,
                enum: 'contentSpecialist',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Content Specialist jobs in Amsterdam',
        alternatives: ['content-specialist-jobs-amsterdam'],
    },

    //Marketing Specialist
    {
        query: 'branch=Marketing&job=marketingSpecialist&city=Amsterdam',
        queryProps: {
            branch: {
                id: 7,
                name: 'Marketing',
            },
            job: {
                name: 'Marketing Specialist',
                branchId: 7,
                jobId: 25,
                enum: 'marketingSpecialist',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Marketing Specialist jobs in Amsterdam',
        alternatives: ['marketing-specialist-jobs-amsterdam'],
    },

    //Social Specialist
    {
        query: 'branch=Marketing&job=socialSpecialist&city=Amsterdam',
        queryProps: {
            branch: {
                id: 7,
                name: 'Marketing',
            },
            job: {
                name: 'Social Specialist',
                branchId: 7,
                jobId: 26,
                enum: 'socialSpecialist',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Social Specialist jobs in Amsterdam',
        alternatives: ['social-specialist-jobs-amsterdam'],
    },

    //SEO Specialist
    {
        query: 'branch=Marketing&job=sEO/SEASpecialist&city=Amsterdam',
        queryProps: {
            branch: {
                id: 7,
                name: 'Marketing',
            },
            job: {
                name: 'SEO / SEA Specialist',
                branchId: 7,
                jobId: 27,
                enum: 'sEO/SEASpecialist',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'SEO Specialist jobs in Amsterdam',
        alternatives: ['seo-specialist-jobs-amsterdam'],
    },

    //SEA Specialist
    {
        query: 'branch=Marketing&job=sEO/SEASpecialist&city=Amsterdam',
        hideInFooter: true,
        queryProps: {
            branch: {
                id: 7,
                name: 'Marketing',
            },
            job: {
                name: 'SEO / SEA Specialist',
                branchId: 7,
                jobId: 27,
                enum: 'sEO/SEASpecialist',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'SEA Specialist jobs in Amsterdam',
        alternatives: ['sea-specialist-jobs-amsterdam'],
    },

    //PR Specialist
    {
        query: 'branch=Marketing&job=pR/CommunicatieSpecialist&city=Amsterdam',
        hideInFooter: true,
        queryProps: {
            branch: {
                id: 7,
                name: 'Marketing',
            },
            job: {
                name: 'PR / Communicatie Specialist',
                branchId: 7,
                jobId: 28,
                enum: 'pR/CommunicatieSpecialist',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'PR Specialist jobs in Amsterdam',
        alternatives: ['pr-specialist-jobs-amsterdam'],
    },

    //Communicatie Specialist
    {
        query: 'branch=Marketing&job=pR/CommunicatieSpecialist&city=Amsterdam',
        queryProps: {
            branch: {
                id: 7,
                name: 'Marketing',
            },
            job: {
                name: 'PR / Communicatie Specialist',
                branchId: 7,
                jobId: 28,
                enum: 'pR/CommunicatieSpecialist',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Communication Specialist jobs in Amsterdam',
        alternatives: ['communication-specialist-jobs-amsterdam'],
    },

    //Customer support
    {
        query: 'branch=Support&job=customerSupport&city=Amsterdam',
        queryProps: {
            branch: {
                id: 8,
                name: 'Support',
            },
            job: {
                name: 'Customer support',
                branchId: 8,
                jobId: 29,
                enum: 'customerSupport',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Customer support jobs in Amsterdam',
        alternatives: ['customer-support-jobs-amsterdam'],
    },

    //Store / Office Manager
    {
        query: 'branch=Support&job=store/OfficeManager&city=Amsterdam',
        queryProps: {
            branch: {
                id: 8,
                name: 'Support',
            },
            job: {
                name: 'Store / Office Manager',
                branchId: 8,
                jobId: 30,
                enum: 'store/OfficeManager',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Office Manager jobs in Amsterdam',
        alternatives: ['office-manager-jobs-amsterdam'],
    },

    //Graphic Designer
    {
        query: 'branch=Design&job=graphicDesigner&city=Amsterdam',
        queryProps: {
            branch: {
                id: 9,
                name: 'Design',
            },
            job: {
                name: 'Graphic Designer',
                branchId: 9,
                jobId: 31,
                enum: 'graphicDesigner',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'Graphic Designer jobs in Amsterdam',
        alternatives: ['graphic-designer-jobs-amsterdam'],
    },

    //UX/UI Designer
    {
        query: 'branch=Design&job=ui/uxDesigner&city=Amsterdam',
        queryProps: {
            branch: {
                id: 9,
                name: 'Design',
            },
            job: {
                name: 'UX/UI Designer',
                branchId: 9,
                jobId: 32,
                enum: 'ui/uxDesigner',
            },
            city: { id: 0, name: 'Amsterdam' },
        },
        seoTitle: 'UX/UI Designer jobs in Amsterdam',
        alternatives: ['ux-ui-designer-jobs-amsterdam'],
    },
];
