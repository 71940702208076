import React, { useEffect, useContext, useReducer } from 'react';
import Link from 'next/link';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { JobPreferenceForm, LoginForm, RegisterForm, VerifyForm, SkillsForm } from '../Form';
import { ModalHeader } from '../../Atoms/Modal';

import useAuth from '../../../hooks/useAuth';
import useSkills from '../../../hooks/useSkills';
import { SearchContext } from '../../../context/SearchContext';
import { Alert } from '../../Atoms/Notications';
import { initialState, reducer } from '../../../reducer/skills';

const AuthForms = (props) => {
    const { onFinish, hideRedirect, switchToLogin, instantUpdateUser } = props;
    const {
        currentStep,
        setCurrentStep,
        handleRegisterRequest,
        handleLoginRequest,
        handleVerifyRequest,
        handlePrevious,
        isFinished,
        authState,
        handleSkills,
        handleJobPrefs,
    } = useAuth();
    const { skillsData } = useSkills();
    const [skillsState, dispatch] = useReducer(reducer, initialState);
    const { branchList, jobList } = useContext(SearchContext);

    useEffect(() => {
        async function fetchSkills() {
            try {
                const { data } = await axios.get(`${process.env.STUDIO_WEB_API}/skills`);
                dispatch({ type: 'set_skills', payload: data });
            } catch (err) {
                return err;
            }
        }

        fetchSkills();

        if (isFinished === true) {
            onFinish();
        }

        if (switchToLogin) {
            setCurrentStep(1);
        }
    }, [isFinished, skillsState.skills]);

    //todo remove to helper
    const JobPrefernceSchema = Yup.object({
        branch: Yup.object().required('Select a branch'),
        job: Yup.object().required('Select a job'),
        workLevel: Yup.object().required('Select your work level'),
    });

    return (
        <>
            {/* Register form */}
            {currentStep === 0 && (
                <>
                    <ModalHeader title="Create account" description="You're almost there! Creating an account is easy and fast." />
                    <Formik
                        initialValues={{
                            firstName: '',
                            lastName: '',
                            email: '',
                            password: '',
                        }}
                        onSubmit={(values) => {
                            handleRegisterRequest(values);
                        }}
                    >
                        {({ values, handleSubmit, handleChange }) => (
                            <form onSubmit={handleSubmit}>
                                <RegisterForm
                                    values={values}
                                    loading={authState.loading}
                                    hideRedirect={hideRedirect}
                                    onChange={handleChange}
                                    handleRedirect={() => setCurrentStep(1)}
                                />
                                {authState.error && authState.message && (
                                    <div className="mt-4">
                                        <Alert data_cy="alert-error-register" status="error" message={authState.message} />
                                    </div>
                                )}
                            </form>
                        )}
                    </Formik>
                </>
            )}

            {/* Login form */}
            {currentStep === 1 && (
                <>
                    <ModalHeader title="Welcome back, good to see you again!" description="Sign in with your email and password" />
                    <Formik
                        initialValues={{
                            username: '',
                            password: '',
                        }}
                        onSubmit={async (values) => {
                            await handleLoginRequest(values);
                        }}
                    >
                        {({ values, handleSubmit, handleChange }) => (
                            <form onSubmit={handleSubmit}>
                                <LoginForm
                                    values={values}
                                    hideRedirect={hideRedirect}
                                    loading={authState.loading}
                                    onChange={handleChange}
                                    handleRedirect={() => setCurrentStep(0)}
                                />
                                {authState.error && authState.message && (
                                    <div className="mt-4">
                                        <Alert data_cy="alert-error-login" status="error" message={authState.message} />
                                        <Link href="/resend-verify">
                                            <p className="underline text-sm text-gray-600 text-center py-2 cursor-pointer">Resend verify email</p>
                                        </Link>
                                    </div>
                                )}
                            </form>
                        )}
                    </Formik>
                </>
            )}

            {/* Onboard form */}

            {/* Job Prefernce form */}
            {currentStep === 2 && (
                <>
                    <ModalHeader data_cy="form_apply_pref" title="Your job preferences" description="Nice to meet you! Tell us a bit about yourself." />
                    <Formik
                        validationSchema={JobPrefernceSchema}
                        initialValues={{
                            branch: '',
                            job: '',
                            workLevel: '',
                        }}
                        onSubmit={(values) => {
                            dispatch({ type: 'add_recommended_skills', payload: { skillsData, jobId: values.job._id } });
                            //Go to next step
                            handleJobPrefs(values, instantUpdateUser);
                        }}
                    >
                        {({ values, handleSubmit, setFieldValue, setFieldTouched, errors, touched }) => (
                            <form onSubmit={handleSubmit}>
                                <JobPreferenceForm
                                    branches={branchList}
                                    jobs={jobList}
                                    values={values}
                                    onChange={(value, name) => setFieldValue(name, value)}
                                    errors={errors}
                                    touched={touched}
                                    onBlur={(name) => setFieldTouched(name, true)}
                                />
                            </form>
                        )}
                    </Formik>
                </>
            )}

            {/* Skills form */}
            {currentStep === 3 && (
                <>
                    <ModalHeader title="Add your skills" description="Show off what you can do! Skills will help us find you even better matching jobs." />
                    <form onSubmit={() => handleSkills(skillsState.selectedSkills, instantUpdateUser)}>
                        <SkillsForm
                            handlePrevious={() => handlePrevious()}
                            recommendedSkills={skillsState.recommendedSkills}
                            selectedSkills={skillsState.selectedSkills}
                            selectSkill={(v) => dispatch({ type: 'select_skill', payload: v })}
                            addSkill={(v) => dispatch({ type: 'add_skill', payload: v })}
                            removeSkill={(v) => dispatch({ type: 'remove_skill', payload: v })}
                            allSkills={skillsState.allSkills}
                        />
                    </form>
                </>
            )}

            {/* Verify form */}
            {currentStep === 4 && (
                <>
                    <Formik
                        initialValues={{
                            v_code: '',
                        }}
                        onSubmit={async (values) => {
                            await handleVerifyRequest({
                                v_code: values.v_code,
                                email: authState.user.email,
                            });
                        }}
                    >
                        {({ values, handleSubmit, setFieldValue }) => (
                            <>
                                <ModalHeader
                                    title="Verify your account"
                                    description="Please check your e-mail for the verification code. Can't find it? Check your spam box!"
                                />
                                <form onSubmit={handleSubmit}>
                                    <VerifyForm
                                        values={values}
                                        loading={authState.loading}
                                        onChange={(v) => setFieldValue('v_code', v)}
                                        userEmail={authState.user.email}
                                    />
                                    {authState.error && authState.message && <Alert status="error" message={authState.message} />}
                                </form>
                            </>
                        )}
                    </Formik>
                </>
            )}
        </>
    );
};

export default AuthForms;
